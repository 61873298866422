import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-life-stats',
  templateUrl: './life-stats.component.html',
  styleUrls: ['./life-stats.component.scss']
})
export class LifeStatsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
