import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TermsLandingComponent } from './terms-landing/terms-landing.component';
import { PrivacyLandingComponent } from './privacy-landing/privacy-landing.component';
import { BabybeatTermsPrivacyComponent } from './babybeat-terms-privacy/babybeat-terms-privacy.component';
import { FetosenseTermsPrivacyComponent } from './fetosense-terms-privacy/fetosense-terms-privacy.component';
import { WrongRouteComponent } from './wrong-route/wrong-route.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { CancelAndReturnComponent } from './cancel-and-return/cancel-and-return.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'company',
    loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
  },
  {
    path: 'offering',
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
  },
  {
    path:'privacy-policy',
    component:PrivacyLandingComponent,
  },
  {
    path:'babybeat-terms-and-privacy-policy',
    component:BabybeatTermsPrivacyComponent,
  },
  {
    path:'fetosense-terms-and-privacy-policy',
    component:FetosenseTermsPrivacyComponent,
  },
  {
    path:'cancellation-and-return-policy',
    component:CancelAndReturnComponent,
  },
  {
    path:'terms-and-conditions',
    component:TermsLandingComponent,
  },
  {
    path:'sitemap',
    component:SitemapComponent,
  },
  {
    path        : '**',
    redirectTo  : 'error-404'
  },
  {
    path        : 'error-404',
    component   : WrongRouteComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
