<section>
    <div class="container inner-section">
        <div class="row mb-5">
            <div class="col-12 text-center">
                <p class="color-light mb-3">Certifications</p>
                <h1 class="color-secondary">Our medical grade solutions have been certified<br>as per the global standards</h1>              
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-6 col-lg-4 text-center standards-inner">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/certifications/certification-ISO.svg">
                <h2 class="color-primary">ISO 13485:2016</h2>
            </div>
            <div class="col-6 col-lg-4 text-center standards-inner">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/certifications/certification-CE.svg">
                <h2 class="color-primary">European Conformity - In-compliance</h2>
            </div>
            <div class="col-6 col-lg-4 text-center standards-inner">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/certifications/certification-CDSCO.svg">
                <h2 class="color-primary">CDSCO VRN</h2>
            </div>
        </div>
    </div>
</section>