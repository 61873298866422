import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
//  import { ScrollToDirective } from './directives/scroll-to.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


const exportedClasses = [
];


@NgModule({
  imports: [CommonModule, NgbModule, RouterModule],
  declarations: exportedClasses,
  exports: exportedClasses
})
export class SharedModule {}
