import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us.component';


const routes: Routes = [
  {
    path: '',
    component: AboutUsComponent,
    data: {
      title: 'NST/CTG Machine Manufacturer in India. 8766531223',
      keywords: "NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, India's most advanced Fetal Heart Monitor, Fetosense, CareNX",
      description: "CareNX Fetosense is a Smartphone based digital, wireless, portable NST/CTG machine, India's most advanced Fetal Heart Monitor in Pune, Mumbai, India. 8766531223"
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AboutUsRoutingModule { }
