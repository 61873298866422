<section id="awards-section" class="bg-color-secondary-lighter">
  <div class="container inner-section">
    <div class="row heading-container" >
        <div class="col-12 text-center">
            <h1 class="color-secondary">Our team has won many global awards<br class="d-none d-md-block">
              and received recognitions</h1>
        </div>
    </div>
    <div class="row gallery-container">
      <!-- Column 1 -->
      <div class="col-md-6 col-lg-3 image-column column-1">
        <div class="inner-img img-1" >
          <img alt="CareNX - World Health Assembly (UN), Geneva" src="../../../../../assets/images/shared/awards/Awards-3.jpg">
          <div class="img-description">
            <p class="text-center text-white">World Health Assembly (UN), Geneva</p>
          </div>
        </div>
        
        <div class="inner-img img-2" >
          <img alt="CareNX - Google SMB Heroes, Singapore" src="../../../../../assets/images/shared/awards/Awards-5.jpg">
          <div class="img-description">
            <p class="text-center text-white">Google SMB Heroes, Singapore</p>
          </div>
        </div>
        
        <div class="proud-moments" >
          <img alt="CareNX - Winner of Accenture Innovation Jockey" src="../../../../../assets/images/company/about/proud-moments/accenture.svg">
          <p class="font-15 text-center">Winner of Accenture Innovation Jockey</p>
        </div>
        
        <div class="inner-img img-3" >
          <img alt="CareNX - World Health Summit, Berlin" src="../../../../../assets/images/shared/awards/Awards-6.jpg">
          <div class="img-description">
            <p class="text-center text-white">World Health Summit, Berlin</p>
          </div>
        </div>
      </div>
      <!-- Column 2 -->
      <div class="col-md-6 col-lg-3 image-column column-2">
        <div class="proud-moments" >
          <img alt="CareNX - Winner of ZS PRIZE: Advancing Healthcare Innovation" src="../../../../../assets/images/company/about/proud-moments/ZS-Prize-Footer-logo-RGB.svg">
          <p class="font-15 text-center">Winner of ZS PRIZE:<br>Advancing Healthcare Innovation</p>
        </div>
        
        <div class="inner-img img-1" >
          <img alt="CareNX - Queens Young Leaders, Buckingham Palace, London" src="../../../../../assets/images/shared/awards/awards-9.jpg">
          <div class="img-description">
            <p class="text-center text-white">Queens Young Leaders, Buckingham Palace, London</p>
          </div>
        </div>
        
        <div class="inner-img img-2" >
          <img alt="CareNX - 3M CII Tech-Innovation, Bangalore" src="../../../../../assets/images/shared/awards/award-10.jpg">
          <div class="img-description">
            <p class="text-center text-white">3M CII Tech-Innovation, Bangalore</p>
          </div>
        </div>
      </div>
      <!-- Column 3 -->
      <div class="col-md-6 col-lg-3 image-column column-3">
        <div class="inner-img img-1" >
          <img alt="CareNX - Magnetic Maharashtra, Mumbai" src="../../../../../assets/images/shared/awards/Awards-4.jpg">
          <div class="img-description">
            <p class="text-center text-white">Magnetic Maharashtra, Mumbai</p>
          </div>
        </div>
        <div class="inner-img img-2" >
          <img alt="CareNX - UN Women, Singapore" src="../../../../../assets/images/shared/awards/awards-8.jpg">
          <div class="img-description">
            <p class="text-center text-white">UN Women, Singapore</p>
          </div>
        </div>
        <div class="proud-moments" >
          <img alt="CareNX - Winner of Global Problem Solver Challenge at CISCO" src="../../../../../assets/images/company/about/proud-moments/1200px-Cisco_logo_blue_2016.svg.svg">
          <p class="font-15 text-center">Winner of Global Problem Solver Challenge at CISCO</p>
        </div>
        <div class="inner-img img-3" >
          <img alt="CareNX - World Health Assembly (UN), Geneva" src="../../../../../assets/images/shared/awards/Awards-3.jpg">
          <div class="img-description">
            <p class="text-center text-white">World Health Assembly (UN), Geneva</p>
          </div>
        </div>
      </div>
      <!-- Column 4 -->
      <div class="col-md-6 col-lg-3 image-column column-4">
        <div class="inner-img img-1" >
          <img alt="CareNX - The President's Innovation Scholar interaction with PM Modi, Delhi" src="../../../../../assets/images/shared/awards/Awards-2.jpg">
          <div class="img-description">
            <p class="text-center text-white">The President's Innovation Scholar interaction with PM Modi, Delhi</p>
          </div>
        </div>
        
        <div class="proud-moments" >
          <img alt="CareNX - Winner of Infosys InfyMakers Award" src="../../../../../assets/images/company/about/proud-moments/Infosys-logo-660x350_large.svg">
          <p class="font-15 text-center">Winner of Infosys InfyMakers Award</p>
        </div>
        
        <div class="inner-img img-2" >
          <img alt="CareNX - IRBI, Moscow" src="../../../../../assets/images/shared/awards/awards-7.jpg">
          <div class="img-description">
            <p class="text-center text-white">IRBI, Moscow</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>