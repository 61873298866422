import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fetosense-terms-privacy',
  templateUrl: './fetosense-terms-privacy.component.html',
  styleUrls: ['./fetosense-terms-privacy.component.scss']
})
export class FetosenseTermsPrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
