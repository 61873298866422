import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HealthcareComponent } from "./healthcare.component";
import { HealthcareRoutingModule } from './healthcare.routing';

@NgModule({
  declarations: [HealthcareComponent],
  imports: [
    CommonModule,
    HealthcareRoutingModule
  ]
})
export class HealthcareModule { }
