<section id="testimonials-section">
    <div class="container inner-section">
        <div class="row">
            <div class="col-12 text-center header">
                <h1 class="h1-large mb-3">Our customers are loving CareNX</h1>
                <p class="p-large text-white">Don't miss out this chance to read some amazing testimonials from
                     doctors, hospitals and mothers about there .
                </p>
            </div>
            <div class="btn-group mt-4">
                <a routerLink="/company/careers" class="btn btn-lg primary-btn" role="button" >
                    Apply Today</a>
            </div>
        </div>
    </div>
</section>