import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-babybeat-locations',
  templateUrl: './babybeat-locations.component.html',
  styleUrls: ['./babybeat-locations.component.scss']
})
export class BabybeatLocationsComponent implements OnInit {
  selectedCity: any;
  cities = [
    { value: 'Mumbai' , label: 'Mumbai'},
    { value: 'Pune' , label: 'Pune'},
    { value: 'Banglore' , label: 'Banglore'},
    { value: 'Hyderabad' , label: 'Hyderabad'},
  ]
  constructor() { }

  ngOnInit() {
  }

}
