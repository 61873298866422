import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LifeCaremotherComponent } from './life-caremother.component';
import { LifeCaremotherRoutingModule } from './life-caremother.routing';

import { ComponentsModule } from '../../shared/components/components.module';
import { LifeIntroComponent } from './components/life-intro/life-intro.component';
import { LifeValuesComponent } from './components/life-values/life-values.component';
import { LifeGrowComponent } from './components/life-grow/life-grow.component';
import { EmployeeTestimonialsComponent } from './components/employee-testimonials/employee-testimonials.component';
import { LifeStatsComponent } from './components/life-stats/life-stats.component';
import { LifeTeamComponent } from './components/life-team/life-team.component';
import { LifeWorkComponent } from './components/life-work/life-work.component';

import { MaterialModule } from 'src/app/shared/modules/material/material.module';
@NgModule({
  declarations: [
    LifeCaremotherComponent, 
    LifeIntroComponent, 
    LifeValuesComponent, 
    LifeGrowComponent, 
    EmployeeTestimonialsComponent,
    LifeStatsComponent, 
    LifeTeamComponent, 
    LifeWorkComponent
  ],
  imports: [
    CommonModule,
    LifeCaremotherRoutingModule,
    ComponentsModule,
    MaterialModule
  ]
})
export class LifeCaremotherModule { }
