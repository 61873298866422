<section id="anandimaa-video">
    <div class="container inner-section">
        <div class="row">
            <div class="col">
                <div class="inner-block">
                    <h1 class="color-secondary text-center">
                        Here’s How AnandiMaa Works
                    </h1>
                    <div class="video d-flex flex-column align-items-center">
                        <img alt="CareNX, high risk pregnancy management platform, CareNX Anandimaa, Pregnancy care for rural mothers" src="../../../../../assets/images/products/fetosense/videos/feto-08.png">
                        <iframe  class="youtube" [src]='safeURL' frameborder="0"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>