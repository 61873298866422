import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-babybeat-terms-privacy',
  templateUrl: './babybeat-terms-privacy.component.html',
  styleUrls: ['./babybeat-terms-privacy.component.scss']
})
export class BabybeatTermsPrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
