import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-fixed-socials',
  templateUrl: './fixed-socials.component.html',
  styleUrls: ['./fixed-socials.component.scss']
})
export class FixedSocialsComponent implements OnInit {
  isVisible: boolean;

  constructor() { }

  ngOnInit() {
  }

  /*@HostListener("window:scroll", [])
  onWindowScroll() {
    if(typeof window !== undefined ){
          
      const offset =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;

      if(offset>700){
        this.isVisible = true;
      }else{
        this.isVisible = false;
      }
    }
  }

  scrollToTop(){
    if(typeof window !== undefined ){
        window.scrollTo({top: 0,behavior: "smooth"});
    }
    
  }*/
}
