import { Component, OnInit } from '@angular/core';
import Glide from '@glidejs/glide'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'
@Component({
  selector: 'app-life-work',
  templateUrl: './life-work.component.html',
  styleUrls: ['./life-work.component.scss']
})
export class LifeWorkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    new Glide('.glide__testimonials_work',{
      type: 'carousel',
      startAt: 0,
      perView: 4,
      autoplay: 2400,
      hoverpause: false,
      animationTimingFunc: 'ease',
      peek: {
        before: 100,
        after: 100
      },
      gap: 15,
      breakpoints: {
        1200: {
          perView: 3,
          peek: {
            before: 80,
            after: 80
          },
        },
        992: {
          perView: 2,
          peek: {
            before: 60,
            after: 60
          },
        },
        750: {
          perView: 1,
          peek: {
            before: 50,
            after: 50
          },
        },
      }
    }).mount({ Controls, Breakpoints })
  }

}
