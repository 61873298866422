<section class="bg-color-secondary-lighter">
    <div class="container-fluid inner-section">
        <div class="row" >
            <div class="col-12 mt-2 d-flex flex-column flex-lg-row align-items-center">
                <div class="details-container">
                    <h1 class="text-white font-45">
                        AI based Solutions for <br class="d-none d-xl-block">
                        Women Health Monitoring
                        <br class="d-none d-xl-block">
                    </h1>
                    <p class="text-white mb-3">
                        Leveraging digital technology to enable access and timely monitoring <br class="d-none d-lg-block">
                        for better Women Healthcare
                         <br class="d-none d-lg-block">
                    </p>
                    <div>
                        <a color="white" button-filled
                        href="https://forms.gle/uv3Bf3iMDzGdPcBs9"target="_blank">
                            Get a Free Demo</a>           
                    </div>
                </div>
                <div class="glide-container">
                    <div class="glide glide__slider">
                        <div class="glide__track" data-glide-el="track">
                            <ul class="glide__slides">
                                <!--Slider 2-->
                                <li class="glide__slide">
                                    <div class="row slide-inner align-items-center">
                                        <div class="col-12 img-container">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" 
                                            src="../../../../assets/images/home/intro-slider/slider-img-1 new.png">
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="row slide-inner align-items-center">
                                        <div class="col-11 img-container">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" 
                                            src="../../../../assets/images/home/intro-slider/slider-img-2.png">
                                        </div>
                                    </div>
                                </li>
                                <!--Slide 3-->
                                <li class="glide__slide">
                                    <div class="row slide-inner align-items-center">
                                        <div class="col-12 img-container">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" 
                                            src="../../../../assets/images/home/intro-slider/slider-img-5.png">
                                        </div>
                                    </div>
                                </li>
                                <!--Slide 4-->
                                <li class="glide__slide">
                                    <div class="row slide-inner align-items-center">
                                        <div class="col-12 img-container">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" 
                                            src="../../../../assets/images/home/intro-slider/slider-img-3.png">
                                        </div>
                                    </div>
                                </li>
                                <!--Slide 5-->
                                <li class="glide__slide">
                                    <div class="row slide-inner align-items-center">
                                        <div class="col-12 img-container">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" 
                                            src="../../../../assets/images/home/intro-slider/slider-img-4.png">
                                        </div>
                                    </div>
                                </li>
                        
                            </ul>
                        </div>
                        <!--
                        <div class="glide__arrows" data-glide-el="controls">
                            <button class="glide__arrow glide__arrow--left" data-glide-dir="<" >
                                <i class="fa fa-angle-left" aria-hidden="true"></i>
                            </button>
                            <button class="glide__arrow glide__arrow--right" data-glide-dir=">" >
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </button>
                        </div>-->
                        <div class="glide__bullets" data-glide-el="controls[nav]">
                            <button class="glide__bullet" data-glide-dir="=0"></button>
                            <button class="glide__bullet" data-glide-dir="=1"></button>
                            <button class="glide__bullet" data-glide-dir="=2"></button>
                            <button class="glide__bullet" data-glide-dir="=3"></button>
                            <button class="glide__bullet" data-glide-dir="=4"></button>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>