import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-about-journey',
  templateUrl: './about-journey.component.html',
  styleUrls: ['./about-journey.component.scss']
})
export class AboutJourneyComponent implements OnInit {
  pressed: boolean = false;
  startx: any;
  value: any;

  constructor() {
    
  }

  ngOnInit() {
  }
  mouseDown = false;

  startX: any;

  scrollLeft: any;

  slider = document.querySelector<HTMLElement>('.timeline-content');

  startDragging(e, flag, el) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.scrollLeft = el.scrollLeft;
  }
  stopDragging(e, flag) {
    this.mouseDown = false;
  }
  moveEvent(e, el) {
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    console.log(e);
    const x = e.pageX - el.offsetLeft;
    const scroll = x - this.startX;
    el.scrollLeft = this.scrollLeft - scroll;
  }

}
