import { Component, HostListener, OnInit } from '@angular/core';
import Glide from '@glidejs/glide'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    new Glide('.glide__slider',{
      type: 'slider',
      startAt: 0,
      perView: 1,
      gap: 0,
      autoplay: 5000,
      hoverpause: false,
    }).mount({ Controls, Breakpoints })
  }

  scrollTo(sectionName){
    let element = document.getElementById(sectionName);
    element.scrollIntoView({behavior:'smooth'});
  }

}
