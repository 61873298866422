<div class="loader" *ngIf="loader">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div class="loaderMain" *ngIf="loaderMain">
  <!--<div class="spinner"></div>-->
  <div class="spinner-bounce">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</div>
<div class="main-wrap">
  <router-outlet></router-outlet>
</div>
