import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CovidComponent } from './covid.component';
import { CovidRoutingModule } from './covid.routing';

@NgModule({
  declarations: [CovidComponent],
  imports: [
    CommonModule,
    CovidRoutingModule
  ]
})
export class CovidModule { }
