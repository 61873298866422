<section class="bg-color-secondary-lighter">
    <div class="container inner-section">
        <div class="row" >
            <div class="col-12 text-center color-secondary inner-container mb-5">
                <h1 class="color-secondary mb-3">Subscribe To Our Newsletter</h1>
                <p class="color-primary">Signup for our weekly newsletter and get the latest news, updates and amazing offers<br class="d-none d-md-block"> delivered directly in your inbox.</p>
            </div>
            <div class="col-12 inner-container-form">
                <form [formGroup]="subscribeForm" (ngSubmit)="subscribe()">
                    <div class="subscribe-form form-group">
                        <input class="form-control form-control-lg" type="text" required
                        placeholder="Enter Your Email Address" formControlName="email" aria-label="Email Address">
                        <button class="ml-2" role="submit" [disabled]="!subscribeForm.valid" color="secondary" button-filled>
                            Subscribe
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>