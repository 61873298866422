<nav class="nav-header navbar" [class.isFixed]="isFixed" [class.isVisible]="isVisible">
    <div class="container-fluid header-inner inner-section">
        <div class="nav-small">
            <a class="nav-logo" routerLink="/" (click)="closeAll()">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/shared/carenx-logo/cnx-logo-purple.png" class="nav-logo-img">
            </a>
            <div class="nav-toggle">
                <div (click)="onClick()" class="nav-toggle-btn" title="Menu">
                    <div class="wrapper-menu" [class.open]="check">
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="line-menu start" src="../../../../assets/images/shared/menu-line.svg" >
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="line-menu mid" src="../../../../assets/images/shared/menu-line.svg" >
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="line-menu end" src="../../../../assets/images/shared/menu-line.svg" >
                    </div>
                </div>
            </div>
        </div>
        <div class="nav-large">
            <ul class="nav-list" [class.toggle]="toggle">
                <li class="nav-list-item drop-toggle">
                    <div #dropToggle class="nav-list-link nav-text" role="button"
                    (click)="toggleDropdown()" id="dropNavItem">
                        Company<i [@fa-animate]="drop_nav" #dropToggleIcon class="fa fa-chevron-down" aria-hidden="true"></i>
                    </div>
                    <div [@dropdownAnimate]="drop_nav" class="drop-nav">
                        <div (click)="closeAll()" class="drop-nav-item" >
                            <a routerLink="/company/about-us" [routerLinkActive]="['dropActive']" class="drop-nav-link"><span>About Us</span></a>
                        </div>
                        <!-- <div (click)="closeAll()" class="drop-nav-item" >
                            <a routerLink="/company/life-at-caremother" [routerLinkActive]="['dropActive']" class="drop-nav-link drop-middle"><span>Life At CareNX</span></a>
                        </div> -->
                        <div (click)="closeAll()" class="drop-nav-item" >
                            <a routerLink="/company/careers" [routerLinkActive]="['dropActive']" class="drop-nav-link"><span>Careers</span></a>
                        </div>
                    </div>
                </li>
                <li (click)="closeAll()" class="nav-list-item" [routerLinkActive]="['active']">
                    <a routerLink="/offering" class="nav-list-link nav-text">Offerings</a>
                </li>
                <li (click)="closeAll()" class="nav-list-item" [routerLinkActive]="['active']">
                    <a routerLink="/contact" class="nav-list-link nav-text">Contact</a>
                </li>
                <li (click)="closeAll()" class="nav-list-item btn-item" *ngIf="!isProductPage">
                    <a class="nav-text" target="_blank" href="https://forms.gle/uv3Bf3iMDzGdPcBs9"
                    color="secondary" button-filled>Request Demo</a>
                </li>
                <li (click)="closeAll()" class="nav-list-item btn-item" *ngIf="isProductPage">
                    <a class="nav-text" href="{{loginUrl}}"
                    color="secondary" button-filled>Login</a>
                </li>
            </ul>
        </div> 
    </div>
</nav>