<section id="bb-video">
    <div class="container inner-section">
        <div class="row">
            <div class="col">
                <div class="inner-block">
                    <h1 class="color-bb-secondary text-center">
                        Here’s How BellyBeat Works
                    </h1>
                    <div class="video d-flex flex-column align-items-center">
                        <img alt="BabyBeat Fetal Monitor in India, CareNX, CareNX providing NST/CTG tests at home" src="../../../../../assets/images/products/babybeat/videos/babybeat page-16.png">
                        <iframe  class="youtube" [src]='safeURL' frameborder="0"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe>
                    </div>
                    <p class="text-center mt-5">
                        Looks interesting? Connect with us now to know more about BellyBeat.<br/>
                        Click on the <a class="color-bb-secondary" target="_blank" href="https://forms.gle/uv3Bf3iMDzGdPcBs9">contact now</a> link to get started.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>