<div [class.isFixed]="isFixed">
    <app-main-navbar></app-main-navbar>
    <!--<app-fixed-socials></app-fixed-socials>-->
    <app-products-intro></app-products-intro>
    <!--<app-page-nav></app-page-nav>-->
    <app-products-fetosense></app-products-fetosense>
    <app-products-babybeat></app-products-babybeat>
    <app-products-sindicolpo></app-products-sindicolpo>
    <app-products-anandimaa></app-products-anandimaa>
    <!--<app-products-element></app-products-element>-->
    <app-footer></app-footer>
</div>