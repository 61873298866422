import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-babybeat-enquiry',
  templateUrl: './babybeat-enquiry.component.html',
  styleUrls: ['./babybeat-enquiry.component.scss']
})
export class BabybeatEnquiryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
