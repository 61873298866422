import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './contact.component';


const routes: Routes = [
  {
    path: '',
    component: ContactComponent,
    data: {
      title: 'CareNX - CTG Machine Manufacturer in India. 8766531223',
      keywords: 'NST / CTG Machine in India, CTG Machine Manufacturer in India, Fetosense, CareNX',
      description: '106, First Floor, A Wing, Greenscape Technocity, Shilphata - Mahape Road,Near Country Inn Hotel, MIDC Industrial Area, Ghansoli, Navi Mumbai, 400710 India'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactRoutingModule { }
