import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from '../shared/components/components.module';
import { MaterialModule } from '../shared/modules/material/material.module';

import { ProductsRoutingModule } from './products.routing';
import { AnandimaaModule } from './anandimaa/anandimaa.module';
import { BabybeatModule } from './babybeat/babybeat.module';
import { FetosenseModule } from './fetosense/fetosense.module';
import { HealthcareModule } from './healthcare/healthcare.module';
import { HomecareModule } from './homecare/homecare.module';
import { ParentModule } from './parent/parent.module';
import { CovidModule } from './covid/covid.module';
import { ProductsComponent } from './products.component';
import { ProductsIntroComponent } from './components/products-intro/products-intro.component';
import { ProductsBabybeatComponent } from './components/products-babybeat/products-babybeat.component';
import { ProductsFetosenseComponent } from './components/products-fetosense/products-fetosense.component';
import { ProductsAnandimaaComponent } from './components/products-anandimaa/products-anandimaa.component';
import { ProductsSindiColpoComponent } from './components/products-sindicolpo/products-sindicolpo.component';
import { ProductsElementComponent } from './components/products-element/products-element.component';
import { SindiColpoModule } from './sindicolpo/sindicolpo.module';

@NgModule({
  declarations: [
    ProductsComponent, 
    ProductsIntroComponent, 
    ProductsBabybeatComponent, 
    ProductsFetosenseComponent, 
    ProductsAnandimaaComponent,
    ProductsSindiColpoComponent,
    ProductsElementComponent,
  ],
  imports: [
    CommonModule,
    ProductsRoutingModule,
    AnandimaaModule,
    BabybeatModule,
    FetosenseModule,
    SindiColpoModule,
    HealthcareModule,
    HomecareModule,
    ParentModule,
    CovidModule,
    ComponentsModule,
    MaterialModule,
  ],
})
export class ProductsModule { }
