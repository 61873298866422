<section id="testimonials-section">
    <div class="container inner-section">
        <div class="row">
            <div class="col-12 header">
                <h1 class="text-center text-white">We're Grateful For Your Feedback!</h1>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12 toggle-buttons mt-3">
                <button (click)="toggleDoctor()" [class.active-btn]="doctorVisible" class="btn-filled-secondary" role="button" >
                    <span>Doctor</span></button>
                <button (click)="togglePatient()" [class.active-btn]="patientVisible" class="btn btn-lg middle-btn" s role="button" >
                    <span>Patient</span></button>
                <button (click)="toggleOthers()" [class.active-btn]="othersVisible" class="btn-filled-secondary" role="button" [hidden]="productsPage" >
                    <span>Others</span></button>
            </div>
        </div>
        <div class="row" [hidden]="!doctorVisible">
            <div class="col-md-12 col-sm-12">
                <div class="glide-container">
                    <div class="glide glide__doctor__testimonials">
                        <div class="glide__track" data-glide-el="track">
                            <ul class="glide__slides">
                                <li class="glide__slide">
                                    <div class="row slide-inner">
                                        <div class="slide-img col-5">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/testimonials/doctor_image.jpg">
                                        </div>
                                        <div class="col-7 slide-data">
                                            <p class="mb-5 font-15">
                                                Fetosense is very sleek and easy to use.
                                                 It was fairly easy to understand all the content of the kit
                                                  and I could perform the test even without referring the manual.
                                            </p>
                                            <p class="doctor-name font-15-bold">Dr.Varsha Degwekar</p>
                                            <p class="font-15">MS Obstetrics & Gynecologist</p>
                                        </div>
                                        <div class="product-name text-center">
                                            <i class="font-15 text-white">Product: Fetosense</i>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="row slide-inner">
                                        <div class="slide-img col-5">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/testimonials/doctor_image.jpg">
                                        </div>
                                        <div class="col-7 slide-data">
                                            <p class="mb-5 font-15">
                                                The training was extremely informative and enabled us to use fetosense effectively.
                                                 I further trained the rest of the staff and they are also able to set up in less than 2 min.
                                                  I have performed more than 15 tests on fetosense,
                                                   when earlier we would just refer these patients outside for the NST.
                                            </p>
                                            <p class="doctor-name font-15-bold">Miss. Neelam More</p>
                                            <p class="font-15">Clinic in-charge at Suburban Maternity Home</p>
                                        </div>
                                        <div class="product-name text-center">
                                            <i class="font-15 text-white">Product: Fetosense</i>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="row slide-inner">
                                        <div class="slide-img col-5">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/testimonials/doctor_image.jpg">
                                        </div>
                                        <div class="col-7 slide-data">
                                            <p class="mb-5 font-15">
                                                Fetosense makes it easier to share its results via
                                                 WhatsApp as it is the most common form of communication,
                                                  I'm able to monitor the graph on my desk while the patient
                                                   is strapped on with the tranducer in the next room.
                                            </p>
                                            <p class="doctor-name font-15-bold font-15-bold">Dr.Sriram Gopal</p>
                                            <p class="font-15">M.D. DGO. D.N.B</p>
                                        </div>
                                        <div class="product-name text-center">
                                            <i class="font-15 text-white">Product: Fetosense</i>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="glide__arrows" data-glide-el="controls">
                            <button class="glide__arrow glide__arrow--left" data-glide-dir="<" >
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                            </button>
                            <button class="glide__arrow glide__arrow--right" data-glide-dir=">" >
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="glide__bullets" data-glide-el="controls[nav]">
                            <button class="glide__bullet" data-glide-dir="=0"></button>
                            <button class="glide__bullet" data-glide-dir="=1"></button>
                            <button class="glide__bullet" data-glide-dir="=2"></button>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
        <div class="row" [hidden]="!patientVisible">
            <div class="col-md-12 col-sm-12">
                <div class="glide-container">
                    <div class="glide glide__patient__testimonials">
                        <div class="glide__track" data-glide-el="track">
                            <ul class="glide__slides">
                                <li class="glide__slide">
                                    <div class="row slide-inner">
                                        <div class="slide-img col-5">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/testimonials/doctor_image.jpg">
                                        </div>
                                        <div class="col-7 slide-data">
                                            <p class="mb-5 font-15">
                                                (Patient's Testimonial)Fetosense is very sleek and easy to use.
                                                 It was fairly easy to understand all the content of the kit
                                                  and I could perform the test even without referring the manual.
                                            </p>
                                            <p class="doctor-name font-15-bold">Dr.Varsha Degwekar</p>
                                            <p class="font-15">MS Obstetrics & Gynecologist</p>
                                        </div>
                                        <div class="product-name text-center">
                                            <i class="font-15 text-white">Product: Fetosense</i>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="row slide-inner">
                                        <div class="slide-img col-5">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/testimonials/doctor_image.jpg">
                                        </div>
                                        <div class="col-7 slide-data">
                                            <p class="mb-5 font-15">
                                                (Patient's Testimonial)The training was extremely informative and enabled us to use fetosense effectively.
                                                 I further trained the rest of the staff and they are also able to set up in less than 2 min.
                                                  I have performed more than 15 tests on fetosense,
                                                   when earlier we would just refer these patients outside for the NST.
                                            </p>
                                            <p class="doctor-name font-15-bold">Miss. Neelam More</p>
                                            <p class="font-15">Clinic in-charge at Suburban Maternity Home</p>
                                        </div>
                                        <div class="product-name text-center">
                                            <i class="font-15 text-white">Product: Fetosense</i>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="row slide-inner">
                                        <div class="slide-img col-5">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/testimonials/doctor_image.jpg">
                                        </div>
                                        <div class="col-7 slide-data">
                                            <p class="mb-5 font-15">
                                                (Patient's Testimonial)Fetosense makes it easier to share its results via
                                                 WhatsApp as it is the most common form of communication,
                                                  I'm able to monitor the graph on my desk while the patient
                                                   is strapped on with the tranducer in the next room.
                                            </p>
                                            <p class="doctor-name font-15-bold">Dr.Sriram Gopal</p>
                                            <p class="font-15">M.D. DGO. D.N.B</p>
                                        </div>
                                        <div class="product-name text-center">
                                            <i class="font-15 text-white">Product: Fetosense</i>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="row slide-inner">
                                        <div class="slide-img col-5">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/testimonials/doctor_image.jpg">
                                        </div>
                                        <div class="col-7 slide-data">
                                            <p class="mb-5 font-15">
                                                (Patient's Testimonial)Fetosense makes it easier to share its results via
                                                 WhatsApp as it is the most common form of communication,
                                                  I'm able to monitor the graph on my desk while the patient
                                                   is strapped on with the tranducer in the next room.
                                            </p>
                                            <p class="doctor-name font-15-bold">Dr.Sriram Gopal</p>
                                            <p class="font-15">M.D. DGO. D.N.B</p>
                                        </div>
                                        <div class="product-name text-center">
                                            <i class="font-15 text-white">Product: Fetosense</i>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="glide__arrows" data-glide-el="controls">
                            <button class="glide__arrow glide__arrow--left" data-glide-dir="<" >
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                            </button>
                            <button class="glide__arrow glide__arrow--right" data-glide-dir=">" >
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="glide__bullets" data-glide-el="controls[nav]">
                            <button class="glide__bullet" data-glide-dir="=0"></button>
                            <button class="glide__bullet" data-glide-dir="=1"></button>
                            <button class="glide__bullet" data-glide-dir="=2"></button>
                            <button class="glide__bullet" data-glide-dir="=3"></button>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
        <div class="row" [hidden]="!othersVisible">
            <div class="col-md-12 col-sm-12">
                <div class="glide-container">
                    <div class="glide glide__others__testimonials">
                        <div class="glide__track" data-glide-el="track">
                            <ul class="glide__slides">
                                <li class="glide__slide">
                                    <div class="row slide-inner">
                                        <div class="slide-img col-5">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/testimonials/doctor_image.jpg">
                                        </div>
                                        <div class="col-7 slide-data">
                                            <p class="mb-5 font-15">
                                                (Others's Testimonial)Fetosense is very sleek and easy to use.
                                                 It was fairly easy to understand all the content of the kit
                                                  and I could perform the test even without referring the manual.
                                            </p>
                                            <p class="doctor-name font-15-bold">Dr.Varsha Degwekar</p>
                                            <p class="font-15">MS Obstetrics & Gynecologist</p>
                                        </div>
                                        <div class="product-name text-center">
                                            <i class="font-15 text-white">Product: Fetosense</i>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="row slide-inner">
                                        <div class="slide-img col-5">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/testimonials/doctor_image.jpg">
                                        </div>
                                        <div class="col-7 slide-data">
                                            <p class="mb-5 font-15">
                                                (Others's Testimonial)The training was extremely informative and enabled us to use fetosense effectively.
                                                 I further trained the rest of the staff and they are also able to set up in less than 2 min.
                                                  I have performed more than 15 tests on fetosense,
                                                   when earlier we would just refer these patients outside for the NST.
                                            </p>
                                            <p class="doctor-name font-15-bold">Miss. Neelam More</p>
                                            <p class="font-15">Clinic in-charge at Suburban Maternity Home</p>
                                        </div>
                                        <div class="product-name text-center">
                                            <i class="font-15 text-white">Product: Fetosense</i>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="row slide-inner">
                                        <div class="slide-img col-5">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/testimonials/doctor_image.jpg">
                                        </div>
                                        <div class="col-7 slide-data">
                                            <p class="mb-5 font-15">
                                                (Others's Testimonial)Fetosense makes it easier to share its results via
                                                 WhatsApp as it is the most common form of communication,
                                                  I'm able to monitor the graph on my desk while the patient
                                                   is strapped on with the tranducer in the next room.
                                            </p>
                                            <p class="doctor-name font-15-bold">Dr.Sriram Gopal</p>
                                            <p class="font-15">M.D. DGO. D.N.B</p>
                                        </div>
                                        <div class="product-name text-center">
                                            <i class="font-15 text-white">Product: Fetosense</i>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="row slide-inner">
                                        <div class="slide-img col-5">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/testimonials/doctor_image.jpg">
                                        </div>
                                        <div class="col-7 slide-data">
                                            <p class="mb-5 font-15">
                                                (Patient's Testimonial)Fetosense makes it easier to share its results via
                                                 WhatsApp as it is the most common form of communication,
                                                  I'm able to monitor the graph on my desk while the patient
                                                   is strapped on with the tranducer in the next room.
                                            </p>
                                            <p class="doctor-name font-15-bold">Dr.Sriram Gopal</p>
                                            <p class="font-15">M.D. DGO. D.N.B</p>
                                        </div>
                                        <div class="product-name text-center">
                                            <i class="font-15 text-white">Product: Fetosense</i>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="row slide-inner">
                                        <div class="slide-img col-5">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/testimonials/doctor_image.jpg">
                                        </div>
                                        <div class="col-7 slide-data">
                                            <p class="mb-5 font-15">
                                                (Patient's Testimonial)Fetosense makes it easier to share its results via
                                                 WhatsApp as it is the most common form of communication,
                                                  I'm able to monitor the graph on my desk while the patient
                                                   is strapped on with the tranducer in the next room.
                                            </p>
                                            <p class="doctor-name font-15-bold">Dr.Sriram Gopal</p>
                                            <p class="font-15">M.D. DGO. D.N.B</p>
                                        </div>
                                        <div class="product-name text-center">
                                            <i class="font-15 text-white">Product: Fetosense</i>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="glide__arrows" data-glide-el="controls">
                            <button class="glide__arrow glide__arrow--left" data-glide-dir="<" >
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                            </button>
                            <button class="glide__arrow glide__arrow--right" data-glide-dir=">" >
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="glide__bullets" data-glide-el="controls[nav]">
                            <button class="glide__bullet" data-glide-dir="=0"></button>
                            <button class="glide__bullet" data-glide-dir="=1"></button>
                            <button class="glide__bullet" data-glide-dir="=2"></button>
                            <button class="glide__bullet" data-glide-dir="=3"></button>
                            <button class="glide__bullet" data-glide-dir="=4"></button>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
    <div class="overlay"></div>
</section>