<section>
    <div class="container inner-section">
        <div class="row">
            <div class="col-12">
                <div>
                    <h1 class="color-secondary text-center">
                        India’s Most Advanced Product For<br class="d-none d-lg-block">
                        Fetal Heart Monitoring With Advanced Key Features
                    </h1>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="mb-4" src="../../../../../assets/images/products/fetosense/feature/central.jpeg">
                    <h2 class="mb-2 text-center color-primary font-22">Central Monitoring</h2>
                    <p class="color-light text-center font-16">Allows multiple CTG visibility on<br class="small-screen d-sm-none d-md-block">
                    PC-Monitor/TV</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="mb-4" src="../../../../../assets/images/products/fetosense/feature/wireless.jpeg">
                    <h2 class="mb-2 text-center color-primary font-22">Wireless & Portable</h2>
                    <p class="color-light text-center font-16">A user-friendly portable kit with<br class="small-screen d-sm-none d-md-block">
                        wireless probes. </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="mb-4" src="../../../../../assets/images/products/fetosense/feature/remote.jpeg">
                    <h2 class="mb-2 text-center color-primary font-22">Remote Monitoring</h2>
                    <p class="color-light text-center font-16">Healthcare providers can monitor<br class="small-screen d-sm-none d-md-block">
                        test results from anywhere.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="mb-4" src="../../../../../assets/images/products/fetosense/feature/printing.jpeg">
                    <h2 class="mb-2 text-center color-primary font-22">A4 Printing</h2>
                    <p class="color-light text-center font-16">Allows printing on A4 size paper<br class="small-screen d-sm-none d-md-block">
                        ie, cost effective & long lasting</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="mb-4" src="../../../../../assets/images/products/fetosense/feature/interpretation.jpeg">
                    <h2 class="mb-2 text-center color-primary font-22">Auto Interpretation</h2>
                    <p class="color-light text-center font-16">A tool which shows real-time<br class="small-screen d-sm-none d-md-block">
                        alerts for abnormal results</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="mb-4" src="../../../../../assets/images/products/fetosense/feature/digital.jpeg">
                    <h2 class="mb-2 text-center color-primary font-22">Digital Records</h2>
                    <p class="color-light text-center font-16">Unlimited Storage and easily share<br class="small-screen d-sm-none d-md-block">
                        reports via email, whatsapp etc.</p>
                </div>
            </div>
        </div>
    </div>
</section>