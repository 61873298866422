import { Component, OnInit } from '@angular/core';
import Glide from '@glidejs/glide'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

@Component({
  selector: 'app-about-us-testimonials',
  templateUrl: './about-us-testimonials.component.html',
  styleUrls: ['./about-us-testimonials.component.scss']
})
export class AboutUsTestimonialsComponent implements OnInit {
  doctorVisible:Boolean = true;
  patientVisible:Boolean = false;
  constructor() {}

  ngOnInit() {
    
    new Glide('.glide__doctor__testimonials',{
      type: 'carousel',
      startAt: 0,
      perView: 1,
      gap: 30,
      focusAt: 'center'
    }).mount({ Controls, Breakpoints })
    
    new Glide('.glide__patient__testimonials',{
      type: 'carousel',
      startAt: 0,
      perView: 1,
      gap: 30,
      focusAt: 'center'
    }).mount({ Controls, Breakpoints })
  }

  toggleTab(event){
    if(event == 'doctors'){
      this.doctorVisible = true;
      this.patientVisible = false;
    }else if(event == 'patients'){
      this.doctorVisible = false;
      this.patientVisible = true;
    }
  }
}
