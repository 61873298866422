import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancel-and-return',
  templateUrl: './cancel-and-return.component.html',
  styleUrls: ['./cancel-and-return.component.scss']
})
export class CancelAndReturnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
