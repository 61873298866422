<nav>
    <div class="container  inner-section">
        <div class="socials-links">
            <a href="https://www.facebook.com/p/Fetosense-100063700014200/" target="_blank">
                <i class="fa fa-facebook-square" aria-hidden="true"></i>
            </a>

            <a href="https://www.facebook.com/p/Fetosense-100063700014200/" target="_blank">
                <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>

            <a href="https://www.facebook.com/p/Fetosense-100063700014200/" target="_blank">
                <i class="fa fa-linkedin-square" aria-hidden="true"></i>
           </a>
        </div>
        <div class="contact-links">
            <span>
                <i class="fa fa-phone" aria-hidden="true"></i>
                <span class="lg-content">+91 93267 75598</span>
                <a class="sm-content" href="tel:+919326775598">+91 93267 75598</a>
            </span>
        </div>
    </div>
</nav>