import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ParentComponent } from "./parent.component";
import { ParentRoutingModule } from './parent.routing';

import { ComponentsModule } from 'src/app/shared/components/components.module';

import { ParentIntroComponent } from './components/parent-intro/parent-intro.component';

@NgModule({
  declarations: [ParentComponent, ParentIntroComponent],
  imports: [
    CommonModule,
    ParentRoutingModule,
    ComponentsModule
  ]
})
export class ParentModule { }
