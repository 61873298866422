<section id="values-section">
    <div class="container inner-section">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="values-collections" >
                    <h2 class="color-secondary text-center text-sm-left font-28 mb-3">
                        Learn more about<br class="d-none d-md-block">
                        the CareNX team
                    </h2>
                    <div class="d-flex justify-content-center justify-content-sm-start">
                        <a routerLink="/company/about-us" color="secondary" button-filled>About Us</a>            
                    </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4">
                <div class="values-collections" >
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/values/passionate.jpeg" class="mb-3">
                    <h2 class="color-primary text-center text-sm-left mb-2">Passionate</h2>
                    <p class="font-15 text-center text-sm-left color-light">
                        We believe that our team brings the <br class="d-none d-xl-block">
                        patience and the passion to reach for the <br class="d-none d-xl-block">
                        stars to change the world for better. 
                    </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="values-collections" >
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/values/diversity.jpeg" class="mb-3">
                    <h2 class="color-primary text-center text-sm-left mb-2">Diversity</h2>
                    <p class="font-15 text-center text-sm-left color-light">
                        Diversity and inclusion, which are the <br class="d-none d-xl-block">
                        real grounds for creativity and change, <br class="d-none d-xl-block">
                        remains at the core of what we do
                    </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="values-collections" >
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/values/teamwork.jpeg" class="mb-3">
                    <h2 class="color-primary text-center text-sm-left mb-2">Teamwork</h2>
                    <p class="font-15 text-center text-sm-left color-light">
                        We nurture individual commitments <br class="d-none d-xl-block">
                        to a group effort-that is what makes <br class="d-none d-xl-block">
                        a team work and thus a company work
                    </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="values-collections" >
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/values/together.jpg" class="mb-3">
                    <h2 class="color-primary text-center text-sm-left mb-2">Grow Together</h2>
                    <p class="font-15 text-center text-sm-left color-light">
                        We believe that the growth can never be <br class="d-none d-xl-block">
                        achieved by mere chances; it is the <br class="d-none d-xl-block">
                        result of forces working together to <br class="d-none d-xl-block">
                        acheive a common goal
                    </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="values-collections" >
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/values/empower.jpeg" class="mb-3">
                    <h2 class="color-primary text-center text-sm-left mb-2">Empower Customer</h2>
                    <p class="font-15 text-center text-sm-left color-light">
                        We believe that our customers are the <br class="d-none d-xl-block">
                        greatest company assets we have. <br class="d-none d-xl-block">
                        Empowering customers with value in turn <br class="d-none d-xl-block">
                        empowers our company.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>