import { NgModule } from "@angular/core";
import { Routes, RouterModule } from '@angular/router';

import { FetosenseComponent } from "./fetosense.component";


const routes: Routes = [

  {
    path: "",
    component: FetosenseComponent,
    data: {
      title: 'Fetosense | A smartphone based portable NST/CTG Machine',
      keywords: 'NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX',
      description: "Fetosense is a smartphone based NST/CTG machine. Wireless | Portable | Remote Monitoring. India's most advanced Fetal Heart Monitor Device in India."
    }
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FetosenseRoutingModule { }
