<section id="awards-section">
    <div class="container inner-section">
        <div class="row mb-5">
            <div class="col-12 text-center">
                <h2 class="introHeader mb-3">AWARDS & RECOGNITIONS</h2>
            </div>
        </div>
        <div class="row awards-one">
            <div class="col-md-6 col-lg-4 col-sm-6 mb-4">
                <div class="card o-hidden h-100">
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="card-img-top" src="./assets/images/landing/products/tinified/Awards-1.jpg">
                    <div class="card-body">
                        <h5 class="personlize-text text-15">
                            Queen's Young Leaders, Buckingham Palace, London
                        </h5>
                    </div>
                </div> 
            </div>
            <div class="col-md-6 col-lg-4 col-sm-6 mb-4">
                <div class="card o-hidden h-100">
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="card-img-top" src="./assets/images/landing/products/tinified/Awards-2.jpg">
                    <div class="card-body">
                        <h5 class="personlize-text text-15">
                            The President's Innovation Scholar interaction with PM Modi, Delhi
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-6 mb-4">
                <div class="card o-hidden h-100">
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="card-img-top" src="./assets/images/landing/products/tinified/Awards-3.jpg">
                    <div class="card-body">
                        <h5 class="personlize-text text-15">
                            Magnetic Maharashtra, Mumbai
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row awards-two">
            <div class="col-md-6 col-lg-4 col-sm-6 mb-4">
                <div class="card o-hidden h-100">
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="card-img-top" src="./assets/images/landing/products/tinified/Awards-4.jpg">
                    <div class="card-body">
                        <h5 class="personlize-text text-15">
                            UN Women, Singapore
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-6 mb-4">
                <div class="card o-hidden h-100">
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="card-img-top" src="./assets/images/landing/products/tinified/Awards-5.jpg">
                    <div class="card-body">
                        <h5 class="personlize-text text-15">
                            World Health summit, Berlin
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-6 mb-4">
                <div class="card o-hidden h-100">
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="card-img-top" src="./assets/images/landing/products/tinified/Awards-6.jpg">
                    <div class="card-body">
                        <h5 class="personlize-text text-15">
                            Google SMB Heroes, Singapore
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="toggle-btn">
                <button role="link" routerLink="/company/about-us" class="btn-filled-secondary">Know More</button>            
            </div>
        </div>
    </div>
</section>