import { Component, OnInit } from '@angular/core';
import Glide from '@glidejs/glide'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

@Component({
  selector: 'app-anandimaa-testimonials',
  templateUrl: './anandimaa-testimonials.component.html',
  styleUrls: ['./anandimaa-testimonials.component.scss']
})
export class AnandimaaTestimonialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    new Glide('.glide__babybeat_testimonials',{
      type: 'slider',
      startAt: 0,
      perView: 3,
      gap: 30,
      peek: {
        before: 30,
        after: -130
      },
      breakpoints: {
        1240: {
          perView: 2,
          gap: 15,
        },
        992: {
          perView: 3,
          gap: 15,
        },
        900: {
          perView: 2,
          gap: 15,
          peek: {
            before: 0,
            after: 0
          }
        },
        576: {
          perView: 1,
          gap: 15,
          peek: {
            before: 0,
            after: 0
          },
        }
      },
      hoverpause: false,
    }).mount({ Controls, Breakpoints })
  }

}
