<section class="bg-color-primary-light">
    <div class="container inner-section">
        <div class="row">
            <div class="col-12">
                <div class="d-flex align-items-center justify-content-center flex-column flex-md-row justify-content-md-start">
                    <div class="d-flex flex-column align-items-center align-items-md-start" >
                        <h2 class="text-md-left text-center text-white font-28 mb-4">
                            Experience free demo of CareNX offerings <br class="d-none d-xl-block"> 
                            that offers quality pregnancy care for healthcare providers.
                        </h2>
                        <a routerLink="/contact" color="white" button-filled>
                                Get In Touch</a>
                    </div>
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/products/human-element/mother.png">
                </div>
            </div>
        </div>
    </div>
</section>