import { Component, HostListener, OnInit } from '@angular/core';

import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-fetosense',
  templateUrl: './fetosense.component.html',
  styleUrls: ['./fetosense.component.scss']
})
export class FetosenseComponent implements OnInit {

  constructor(
    private title: Title,
    private meta: Meta) {
      
    this.title.setTitle('Fetosense | A smartphone based portable NST/CTG Machine');
    this.meta.updateTag({ name: 'description', content: "Fetosense is a smartphone based NST/CTG machine. Wireless | Portable | Remote Monitoring. India's most advanced Fetal Heart Monitor Device in India." })
    this.meta.updateTag({ name: 'keywords', content: "NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" })
    }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  isFixed: boolean;
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if(typeof window !== undefined ){
          
      const offset =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (offset > 400) {
        this.isFixed = true;
      } else if(offset <= 500){
        this.isFixed = false
      }
      else {
        this.isFixed = false;
      }
    }
  }
}
