<section [class.isFixed]="isFixed" [class.isVisible]="isVisible">
    <div class="container-fluid inner-section">
        <nav class="navbar">
            <div class="nav-inner">
              <ul class="nav-item-container">
                <li class="nav-item" *ngFor="let item of navItems">
                    <a class="nav-link" [ngClass]="item.navScroll" (click)="scrollTo(item.navScroll)" >
                      <span>{{item.navName}}</span>
                    </a>
                </li>
              </ul>
            </div>
        </nav>
    </div>
</section>