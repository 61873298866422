<section class="bg-color-bb">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="col-12 col-md-6 px-0">
                <div class="left-block">
                    <img alt="BabyBeat Fetal Monitor in India, CareNX, CareNX providing NST/CTG tests at home" src="../../../../../assets/images/products/babybeat/enquiry/babybeat-07.png">
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="right-block d-flex align-items-center justify-content-center">
                    <div>
                        <h1 class="mb-4 text-center text-lg-left text-white">
                            Listen to the very first<br class="d-none d-sm-block">
                            heartbeat of your baby with<br class="d-none d-sm-block">
                            CareNX BellyBeat
                        </h1>
                        <div class="btn-grp d-flex flex-column align-items-center flex-lg-row">
                            <a href="https://forms.gle/uv3Bf3iMDzGdPcBs9" target="_blank"
                            color="white" text="babybeat" button-outlined>How to get BellyBeat<i class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>