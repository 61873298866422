<section class="bg-color-secondary">
    <div class="container-fluid  inner-section">
        <div class="row">
            <div class="col-12 col-md-6 px-0">
                <div class="left-block">
                    <img alt="CareNX, high risk pregnancy management platform, CareNX Anandimaa, Pregnancy care for rural mothers" src="../../../../../assets/images/products/anandimaa/enquiry/aanandimaa-07.png">
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="right-block d-flex align-items-center justify-content-center">
                    <div>
                        <h1 class="mb-4 text-center text-lg-left text-white">
                            Experience remote pregnancy<br>
                            care with governments & NGOs<br>
                            with CareNX AnandiMaa
                        </h1>
                        <div class="btn-grp d-flex flex-column align-items-center flex-lg-row">
                            <a href="https://forms.gle/uv3Bf3iMDzGdPcBs9" target="_blank" color="white" text="secondary" button-outlined>Get Free Demo</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>