<section id="workplace-section">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="col-12 text-center header" >
                <h1 class="mb-2 color-secondary">Life At CareNX</h1>
                <p class="color-primary font-16">
                    While we’re passionate and serious about our ambitions, we leave plenty of time<br>
                    to play and have fun as well.
                </p>
            </div>
        </div>
        <div class="glide-container" >
            <div class="glide glide__testimonials_work">
                <div class="glide__track" data-glide-el="track">
                    <ul class="glide__slides">
                        <li class="glide__slide">
                            <div class="slide-inner flex-column">
                                <div class="slider-img">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/work-hard/2.jpeg">
                                </div>
                                <div class="image-spacing"></div>
                            </div>
                        </li>
                        <!--Slider 2-->
                        <li class="glide__slide">
                            <div class="slide-inner flex-column">
                                <div class="image-spacing"></div>
                                <div class="slider-img">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/work-hard/3.jpeg">
                                </div>
                            </div>
                        </li>
                        <!--Slide 3-->
                        <li class="glide__slide">
                            <div class="slide-inner flex-column">
                                <div class="slider-img">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/work-hard/4.jpeg">
                                </div>
                                <div class="image-spacing"></div>
                            </div>
                        </li>
                        <!--Slide 4-->
                        <li class="glide__slide">
                            <div class="slide-inner flex-column">
                                <div class="image-spacing"></div>
                                <div class="slider-img">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/work-hard/5.jpeg">
                                </div>
                            </div>
                        </li>
                        <!--Slide 5-->
                        <li class="glide__slide">
                            <div class="slide-inner flex-column">
                                <div class="slider-img">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/work-hard/6.jpeg">
                                </div>
                                <div class="image-spacing"></div>
                            </div>
                        </li>
                        <!--Slide 6-->
                        <li class="glide__slide">
                            <div class="slide-inner flex-column">
                                <div class="image-spacing"></div>
                                <div class="slider-img">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/work-hard/7.jpg">
                                </div>
                            </div>
                        </li>
                        <!--Slide 7-->
                        <li class="glide__slide">
                            <div class="slide-inner flex-column">
                                <div class="slider-img">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/work-hard/8.jpg">
                                </div>
                                <div class="image-spacing"></div>
                            </div>
                        </li>
                        <!--Slider 8-->
                        <li class="glide__slide">
                            <div class="slide-inner flex-column">
                                <div class="image-spacing"></div>
                                <div class="slider-img">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/work-hard/9.jpg">
                                </div>
                            </div>
                        </li>
                        <!--Slide 9-->
                        <li class="glide__slide">
                            <div class="slide-inner flex-column">
                                <div class="slider-img">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/work-hard/10.jpg">
                                </div>
                                <div class="image-spacing"></div>
                            </div>
                        </li>
                        <!--Slide 10-->
                        <li class="glide__slide">
                            <div class="slide-inner flex-column">
                                <div class="image-spacing"></div>
                                <div class="slider-img">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/work-hard/11.jpeg">
                                </div>
                            </div>
                        </li>
                        <!--Slide 11-->
                        <li class="glide__slide">
                            <div class="slide-inner flex-column">
                                <div class="slider-img">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/work-hard/12.jpg">
                                </div>
                                <div class="image-spacing"></div>
                            </div>
                        </li>
                        <!--Slide 12-->
                        <li class="glide__slide">
                            <div class="slide-inner flex-column">
                                <div class="image-spacing"></div>
                                <div class="slider-img">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/work-hard/1.jpeg">
                                </div>
                            </div>
                        </li>
                        <!--Slide 13-->
                        <li class="glide__slide">
                            <div class="slide-inner flex-column">
                                <div class="slider-img">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/work-hard/WhatsApp Image 2022-01-06 at 2.38.34 PM (2).jpeg">
                                </div>
                                <div class="image-spacing"></div>
                            </div>
                        </li>
                        <!--Slide 14-->
                        <li class="glide__slide">
                            <div class="slide-inner flex-column">
                                <div class="image-spacing"></div>
                                <div class="slider-img">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/work-hard/WhatsApp Image 2022-01-06 at 2.42.22 PM (2).jpeg">
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>                    
        </div>
        <div class="row">
            <div class="col-12 text-center header">
                <a [routerLink] = "['/company/careers']" fragment="openings" class="font-20 color-secondary">See Open Roles &#8594;</a>
            </div>
        </div>
    </div>
</section>