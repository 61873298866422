import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anandimaa-reach',
  templateUrl: './anandimaa-reach.component.html',
  styleUrls: ['./anandimaa-reach.component.scss']
})
export class AnandimaaReachComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
