import { NgModule } from "@angular/core";
import { Routes, RouterModule } from '@angular/router';

import { CovidComponent } from "./covid.component";

const routes: Routes = [

  {
    path: "",
    component: CovidComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CovidRoutingModule { }
