import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fetosense-locations',
  templateUrl: './fetosense-locations.component.html',
  styleUrls: ['./fetosense-locations.component.scss']
})
export class FetosenseLocationsComponent implements OnInit {
  selectedCity: any;
  cities = [
    { value: 'Mumbai' , label: 'Mumbai'},
    { value: 'Pune' , label: 'Pune'},
    { value: 'Jaipur' , label: 'Jaipur'},
    { value: 'Telangana' , label: 'Telangana'},
  ]
  constructor() { }

  ngOnInit() {
  }

}
