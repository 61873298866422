import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutUsComponent } from './about-us.component';
import { AboutUsRoutingModule } from './about-us.routing';

import { ComponentsModule } from '../../shared/components/components.module';
import { AboutUsIntroComponent } from './components/about-us-intro/about-us-intro.component';
import { AboutUsAwardsComponent } from './components/about-us-awards/about-us-awards.component';
import { AboutUsPublicationsComponent } from './components/about-us-publications/about-us-publications.component';
import { AboutUsDetailsComponent } from './components/about-us-details/about-us-details.component';

import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { AboutUsTestimonialsComponent } from './components/about-us-testimonials/about-us-testimonials.component';
import { AboutJourneyComponent } from './components/about-journey/about-journey.component';
import { AboutBlogsComponent } from './components/about-blogs/about-blogs.component';
import { StayTunedComponent } from './components/stay-tuned/stay-tuned.component';
@NgModule({
  declarations: [
    AboutUsComponent, 
    AboutUsIntroComponent, 
    AboutUsAwardsComponent, 
    AboutUsPublicationsComponent,
    AboutUsDetailsComponent,
    AboutUsTestimonialsComponent,
    AboutJourneyComponent,
    AboutBlogsComponent,
    StayTunedComponent
  ],
  imports: [
    CommonModule,
    AboutUsRoutingModule,
    ComponentsModule,
    MaterialModule
  ],
  bootstrap: [
    AboutUsComponent, 
    AboutUsIntroComponent, 
    AboutUsAwardsComponent, 
    AboutUsPublicationsComponent,
    AboutUsDetailsComponent,
    AboutUsTestimonialsComponent,
    AboutJourneyComponent,
    AboutBlogsComponent
  ]
})
export class AboutUsModule { }
