import { Component, OnInit } from '@angular/core';
import Glide from '@glidejs/glide'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: 'app-tab-testimonials',
  templateUrl: './tab-testimonials.component.html',
  styleUrls: ['./tab-testimonials.component.scss']
})
export class TabTestimonialsComponent implements OnInit {
  doctorVisible:Boolean = true;
  patientVisible:Boolean = false;
  othersVisible:Boolean = false;
  productsPage:Boolean = true;
  constructor(private router: Router) {
    if(this.router.url === '/products'){
      this.productsPage = false;
    }
  }

  ngOnInit() {
    
    new Glide('.glide__doctor__testimonials',{
      type: 'carousel',
      startAt: 0,
      perView: 1,
      gap: 30,
      focusAt: 'center'
    }).mount({ Controls, Breakpoints })
    
    new Glide('.glide__patient__testimonials',{
      type: 'carousel',
      startAt: 0,
      perView: 1,
      gap: 30,
      focusAt: 'center'
    }).mount({ Controls, Breakpoints })

    new Glide('.glide__others__testimonials',{
      type: 'carousel',
      startAt: 0,
      perView: 1,
      gap: 30,
      focusAt: 'center'
    }).mount({ Controls, Breakpoints })
  }

  toggleDoctor(){
    this.doctorVisible = true;
    this.patientVisible = false;
    this.othersVisible = false;
  }

  togglePatient(){
    this.doctorVisible = false;
    this.patientVisible = true;
    this.othersVisible = false;
  }

  toggleOthers(){
    this.doctorVisible = false;
    this.patientVisible = false;
    this.othersVisible = true;
  }
}
