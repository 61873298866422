import {NgModule} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon'
import {CdkAccordionModule} from '@angular/cdk/accordion';

@NgModule({
  exports: [
    MatRippleModule,
    MatProgressBarModule,
    MatSelectModule,
    MatIconModule,
    CdkAccordionModule
  ]
})
export class MaterialModule {}