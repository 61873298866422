<section id="team-section">
    <div class="container-block">
        <div class="bg-color-secondary-lighter right-bg"></div>
        <div class="container inner-section">
            <div class="row">
                <div class="col-12">
                    <div class="display-block d-flex flex-column-reverse flex-lg-row align-items-center justify-content-lg-between">
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX"  src="../../../../../assets/images/company/life/team/team-21.png">
                        <div class="inner-details mb-5 mb-lg-0">
                            <div class="details-container">
                                <div class="" >
                                    <h1 class="mb-4 color-secondary">Mr. Aditya Kulkarni, Founder</h1>
                                    <p class="font-15 color-primary mb-4">
                                        We are a team of passionate individuals<br class="d-none d-lg-block">
                                        committed to provide a better women healthcare<br class="d-none d-lg-block">
                                        for doctors, women and healthcare providers.<br class="d-none d-lg-block">
                                        We believe collaboration is the key to success.
                                    </p>
                                    <p class="font-15 color-primary">
                                        Each team member has a huge opportunity<br class="d-none d-lg-block">
                                        to make a big impact, and together, as a<br class="d-none d-lg-block">
                                        distributed and connected team, we support<br class="d-none d-lg-block">
                                        each other in every area of the company to<br class="d-none d-lg-block">
                                        achieve it.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid inner-section">
        <div class="d-flex flex-lg-row flex-column container-inner">
            <div class="teams-container bg-color-primary-light">
                <p class="font-20 text-center text-lg-left text-white my-3" >
                    We’re a close-knit team full of<br class="d-none d-lg-block">
                    talented and passionate people,<br class="d-none d-lg-block">
                    and growing quickly.
                </p>
            </div>
            <div class="glide-container">
                <div class="glide glide__testimonials_team">
                    <div class="glide__track" data-glide-el="track" >
                        <ul class="glide__slides">
                            <li class="glide__slide">
                                <div class="row slide-inner justify-content-center justify-content-lg-start">
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/team/images/keshav.jpeg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Keshav Barai</p>
                                                <p class="text-center font-12 color-light">Operations Manager</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/team/images/dipneeta.jpeg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Dipneeta Barad</p>
                                                <p class="text-center font-12 color-light">Accountant and Admin</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/team/images/tushar.jpeg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Tushar Chavan</p>
                                                <p class="text-center font-12 color-light">Support Executive</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/team/images/sanofar.jpeg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Sanofar Shaikh</p>
                                                <p class="text-center font-12 color-light">Visual Designer</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!--Slide 2-->
                            <li class="glide__slide">
                                <div class="row slide-inner justify-content-center justify-content-lg-start">
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/team/images/kiran.jpg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Kiran Kumawat</p>
                                                <p class="text-center font-12 color-light">Web Developer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/team/images/Harshad.jpg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Harshad Narkhede</p>
                                                <p class="text-center font-12 color-light">Software Engineer</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/team/images/riken.jpg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Riken Joshi</p>
                                                <p class="text-center font-12 color-light">Regional Sales Manager</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/company/life/team/images/kajal.jpeg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Kajal Singh</p>
                                                <p class="text-center font-12 color-light">Software Tester</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                    <div class="glide__arrows d-none d-lg-block" data-glide-el="controls">
                        <button class="glide__arrow glide__arrow--left" data-glide-dir="<" >
                            <i class="fa fa-angle-left" aria-hidden="true"></i>
                        </button>
                        <button class="glide__arrow glide__arrow--right" data-glide-dir=">" >
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </button>
                    </div>
                    
                    <div class="glide__bullets d-block d-lg-none" data-glide-el="controls[nav]">
                        <button class="glide__bullet" data-glide-dir="=0"></button>
                        <button class="glide__bullet" data-glide-dir="=1"></button>
                        <button class="glide__bullet" data-glide-dir="=2"></button>
                        <button class="glide__bullet" data-glide-dir="=3"></button>
                    </div>
                </div>                    
            </div>
        </div>
    </div>
</section>