<section id="testimonials-section">
    <div class="container inner-section">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="glide-container">
                    <div class="glide glide__people">
                        <div class="glide__track" data-glide-el="track">
                            <ul class="glide__slides">
                                <li class="glide__slide">
                                    <div class="row slide-inner">
                                        <div class="col-12 slide-data text-center">
                                            <h5 class="mb-4">
                                                Fetosense is very sleek and easy to use.
                                                 It was fairly easy to understand all the content of the kit
                                                  and I could perform the test even without referring the manual.
                                            </h5>
                                            <h4>Dr.Varsha Degwekar</h4>
                                            <h5>MS Obstetrics & Gynecologist</h5>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="row slide-inner">
                                        <div class="col-12 slide-data text-center">
                                            <h5 class="mb-4">
                                                The training was extremely informative and enabled us to use fetosense effectively.
                                                 I further trained the rest of the staff and they are also able to set up in less than 2 min.
                                                  I have performed more than 15 tests on fetosense,
                                                   when earlier we would just refer these patients outside for the NST.
                                            </h5>
                                            <h4>Miss. Neelam More</h4>
                                            <h5>Clinic in-charge at Suburban Maternity Home</h5>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="row slide-inner">
                                        <div class="col-12 slide-data text-center">
                                            <h5 class="mb-4">
                                                fetosense makes it easier to share its results via
                                                 WhatsApp as it is the most common form of communication,
                                                  I'm able to monitor the graph on my desk while the patient
                                                   is strapped on with the tranducer in the next room.
                                            </h5>
                                            <h4>Dr.Sriram Gopal</h4>
                                            <h5>M.D. DGO. D.N.B</h5>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="glide__arrows" data-glide-el="controls">
                            <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                            </button>
                            <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="glide__bullets" data-glide-el="controls[nav]">
                            <button class="glide__bullet" data-glide-dir="=0"></button>
                            <button class="glide__bullet" data-glide-dir="=1"></button>
                            <button class="glide__bullet" data-glide-dir="=2"></button>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>