<section id="openings">
  <div class="container-fluid inner-section">
    <div class="row">
      <div class="col-12">
        <div class="inner-block d-sm-flex align-items-center justify-content-between">
          <h1 class="mb-3 color-bb-secondary">Frequently Asked Questions</h1>
          <mat-form-field [style.width.px]=250 appearance="fill">
            <mat-label>Filters</mat-label>
            <mat-select [(value)]="selectedFilter" placeholder="Mode" (selectionChange)="applyFilter($event)">
              <mat-option *ngFor="let filter of filters" [value]="filter.value">
                {{filter.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="card-block">
          <cdk-accordion class="example-accordion">
            <cdk-accordion-item *ngFor="let item of faqList | async" #accordionItem="cdkAccordionItem"
              class="example-accordion-item" role="button" tabindex="0" [attr.expanded]="accordionItem.expanded"
              [attr.id]="'accordion-header'" [attr.aria-expanded]="accordionItem.expanded"
              [attr.aria-controls]="'accordion-body'">
              <div style="padding-top: 30px;">
                <div class="">
                  <div class="faq-card">
                    <div class="example-accordion-item-header accordian-inner" (click)="accordionItem.toggle();">
                      <div class="d-flex justify-content-between">
                        <h2 class="font-22 color-primary">{{item.title}}</h2>
                        <i class="fa fa-angle-down" [class.rotate]="accordionItem.expanded"
                          [class.rotate-inverse]="!accordionItem.expanded" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="example-accordion-item-body" role="region"
                      [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body'"
                      [attr.aria-labelledby]="'accordion-header'">
                      <div class="accordian-inner">
                        <div class="mt-1 mb-3" *ngIf="item.body">
                          <p class="font-16 color-light">{{item.body}}</p>
                        </div>
                        <div *ngIf="item.para">
                          <div class="mt-1 mb-3" *ngFor="let paraItem of item.para">
                            <p class="font-16 color-light">{{paraItem}}</p>
                          </div>
                        </div>
                        <div *ngIf="item.images" class="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-start">
                            <div class="mt-1 mb-3" *ngFor="let image of item.images">
                                <img class="faq-images mr-0 mr-sm-2" src="{{image}}" alt="">
                            </div>
                        </div>
                        <div class="mt-1 mb-3" *ngIf="item.listHead">
                          <p class="font-16 color-light">{{item.listHead}}</p>
                        </div>
                        <div class="mt-1 mb-3" *ngIf="item.listUnordered">
                          <ul>
                            <li *ngFor="let listItem of item.listUnordered">
                              <p class="font-16 color-light">{{listItem}}</p>
                            </li>
                          </ul>
                        </div>
                        <div class="mt-1 mb-3" *ngIf="item.listFoot">
                          <p class="font-16 color-light">{{item.listFoot}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </cdk-accordion-item>
          </cdk-accordion>
        </div>
      </div>
    </div>
  </div>
</section>
