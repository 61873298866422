import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AwardsComponent } from './awards/awards.component';
import { FixedSocialsComponent } from './fixed-socials/fixed-socials.component';
import { FooterComponent } from './footer/footer.component';
import { MainNavbarComponent } from './main-navbar/main-navbar.component';
import { PartnersComponent } from './partners/partners.component';
import { SocialHeaderComponent } from './social-header/social-header.component';
import { YoutubeVideoComponent } from './youtube-video/youtube-video.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { PeopleTestimonialsComponent } from './people-testimonials/people-testimonials.component';
import { PageNavComponent } from './page-nav/page-nav.component';
import { FeaturedInComponent } from './featured-in/featured-in.component';

import { MaterialModule } from '../modules/material/material.module';
import { TabTestimonialsComponent } from './tab-testimonials/tab-testimonials.component';
import { NewsComponent } from './news/news.component';

@NgModule({
  declarations: [
    AwardsComponent,
    FixedSocialsComponent,
    FooterComponent,
    MainNavbarComponent,
    PartnersComponent,
    SocialHeaderComponent,
    YoutubeVideoComponent,
    TestimonialsComponent,
    PeopleTestimonialsComponent,
    PageNavComponent,
    FeaturedInComponent,
    TabTestimonialsComponent,
    NewsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule
  ],
  exports: [
    AwardsComponent,
    FixedSocialsComponent,
    FooterComponent,
    MainNavbarComponent,
    PartnersComponent,
    SocialHeaderComponent,
    YoutubeVideoComponent,
    TestimonialsComponent,
    PeopleTestimonialsComponent,
    PageNavComponent,
    FeaturedInComponent,
    TabTestimonialsComponent,
    NewsComponent
  ]
})
export class ComponentsModule { }
