<section>
    <div class="container inner-section">
        <div class="row mb-5" >
            <div class="col-12">
                <p class="color-light text-center text-md-left mb-3">Certifications</p>
                <h1 class="color-secondary text-center text-md-left">Our innovations have been validated and supported by global organizations <br class="d-none d-xl-block">
                </h1>
            </div>
        </div>
        <div class="d-md-flex align-items-center" >
            <div class="cert-container mb-5 mb-md-0">
                <div class="mb-4 mb-md-0 mt-md-3">
                    <div class="cert-img d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-start">
                        <img class="mr-md-4 mb-2 mb-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/certifications/certification-ISO.svg">
                        <h2 class="color-primary cert-title font-15">ISO <br class="small-br">13485:2016</h2>
                    </div>
                </div>
                <div class="mb-4 mb-md-0 my-md-5">
                    <div class="cert-img d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-start">
                        <img class="mr-md-4 mb-2 mb-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/certifications/certification-CE.svg">
                        <h2 class="color-primary cert-title font-15">European <br class="small-br">Conformity - In-compliance</h2>
                    </div>
                </div>
                <div class="mb-md-3">
                    <div class="cert-img d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-start">
                        <img class="mr-md-4 mb-2 mb-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/certifications/certification-CDSCO.svg">
                        <h2 class="color-primary cert-title font-15">CDSCO <br class="small-br">VRN</h2>
                    </div>
                </div>
            </div>
            <hr>
            <div class="w-100 partner-container">
                <div class="inner-block">
                    <h3 class="color-secondary mb-3 text-center text-md-left">Validation partners</h3>
                    <div class="row">
                        <div class="col-6 col-lg-4 d-flex justify-content-center justify-content-md-start">
                            <img class="partner-img mt-4 mt-sm-3 mt-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/shared/partners/KEM logo-01.png">
                        </div>
                        <div class="col-6 col-lg-4 d-flex justify-content-center justify-content-md-start">
                            <img class="partner-img mt-4 mt-sm-3 mt-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/shared/partners/nanavati.png">
                        </div>
                    </div>
                </div>
                <hr>
                <div class="">
                    <h3 class="color-secondary mb-3 text-center text-md-left">Technology partners</h3>
                    <div class="row">
                        <div class="col-6 col-lg-3 d-flex justify-content-center justify-content-md-start">
                            <img class="partner-img mt-4 mt-sm-3 mt-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/shared/partners/google.png">
                        </div>
                        <div class="col-6 col-lg-3 d-flex justify-content-center justify-content-md-start">
                            <img class="partner-img mt-4 mt-sm-3 mt-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/shared/partners/cisco.png">
                        </div>
                        <div class="col-6 col-lg-3 d-flex justify-content-center justify-content-md-start">
                            <img class="partner-img mt-4 mt-sm-3 mt-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/shared/partners/solve-logo-newsbg.png">
                        </div>
                        <div class="col-6 col-lg-3 d-flex justify-content-center justify-content-md-start">
                            <img class="partner-img mt-4 mt-sm-3 mt-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/shared/partners/IITBombay-logo.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>