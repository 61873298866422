<section class="">
    <!--<img class="marketer-logo top-right d-none d-lg-block" src="../../../../../assets/images/products/babybeat/intro/bsv logo.png" alt="marketed by bsv">-->
    <div class="container-fluid inner-section">
        <div class="row align-items-center justify-content-center justify-content-lg-unset">
            <div class="col-12 col-sm-8 col-md-7 col-lg-5">
                <div class="details-container mb-5 mb-lg-0">
                    <img alt="BabyBeat Fetal Monitor in India, CareNX, CareNX providing NST/CTG tests at home" class="mb-4" src="../../../../../assets/images/products/babybeat/intro/babybeatlogo-white.png">
                    <h1 class="text-white font-45 mb-3">BellyBeat</h1>
                    <h2 class="text-white mb-4">
                        Listen to the rhythm of your baby’s heartbeat <br>
                        now at home
                    </h2>
                    <div>
                        <a href="https://forms.gle/uv3Bf3iMDzGdPcBs9" color="white" button-filled>Get Free Demo</a>            
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-8 col-md-7 col-lg-7 d-none d-lg-block">
                <div class="image-container mt-5 mt-lg-0 d-flex align-items-center justify-content-end">
                    <img alt="BabyBeat Fetal Monitor in India, CareNX, CareNX providing NST/CTG tests at home" src="../../../../../assets/images/products/babybeat/intro/bb banner product.png">
                </div>
            </div>
        </div>
    </div>
</section>
<div class="small-product-image">
    <div class="container-fluid inner-section">
        <div class="row justify-content-center">
            <div class="col-12 col-sm-8 col-md-7 d-block d-lg-none">
                <div class="mt-5">
                    <img class="marketer-logo" alt="BabyBeat Fetal Monitor in India, CareNX, CareNX providing NST/CTG tests at home" src="../../../../../assets/images/products/babybeat/intro/bsv logo.png">
                </div>
                <div class="mb-5">
                    <img alt="BabyBeat Fetal Monitor in India, CareNX, CareNX providing NST/CTG tests at home" src="../../../../../assets/images/products/babybeat/intro/bb banner product.png">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="banner-bottom">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="col-12">
                <div class="inner-block d-flex align-items-center">
                    <img class="d-none d-lg-block" src="../../../../../assets/images/products/babybeat/intro/png-transparent-logo-amazon-com-brand-flipkart-others-text-orange-logo.png" alt="bsv logo">
                    <p class="color-primary">Product 'BellyBeat Basic' is also available in India on Amazon.com in India. <a href="https://www.amazon.in/dp/B0CJ9WWQZ5">Buy Now!</a></p>
                </div>
            </div>
        </div>
    </div>
</div>