<section>
    <div class="left-bg bg-color-secondary-lighter d-none d-lg-block">
        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/products/sindicolpo/stats/sindi-07.png">
    </div>
    <div class="container-fluid inner-section">
        <div class="row align-items-center">
            <div class="col-12">
                <div class="inner-block d-flex flex-column-reverse flex-lg-row align-items-center justify-content-between">
                    
                    <div class="details-container d-flex flex-column align-items-center align-items-lg-start justify-content-center mb-4 mb-md-0" >
                        <div class="d-flex justify-content-lg-start">
                            <div class="stats d-flex flex-column align-items-center d-lg-block">
                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="mb-3" src="../../../../../assets/images/products/sindicolpo/stats/sindi-17.jpeg">
                                <h1 class="color-secondary">13+</h1>
                                <p class="color-secondary text-center text-lg-left">States Covered</p>
                            </div>
                            <div class="stats right-content d-flex flex-column align-items-center d-lg-block">
                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="mb-3" src="../../../../../assets/images/products/sindicolpo/stats/sindi-18.jpeg">
                                <h1 class="color-secondary">1000+</h1>
                                <p class="color-secondary text-center text-lg-left">Females Screened</p>
                            </div>
                        </div>
                        <p class="my-4 text-lg-left text-center">
                            Our interactive innovation enables to screen<br>
                            cervical canccer remotely from anywhere, at anytime<br>
                            for the doctors 
                        </p>
                        <a href="https://forms.gle/uv3Bf3iMDzGdPcBs9" target="_blank" color="secondary" button-filled>Free Demo Available</a>
                    </div>
                    
                    <div class="image-container mb-4 mb-lg-0 d-flex align-items-center justify-content-center">
                        <img alt="We’re CareNX, A Digital Maternity Solution Platform For Healthcare Providers" src="../../../../../assets/images/products/sindicolpo/intro/sindi-mobile-stitch.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>