import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';

import { HomeModule } from './home/home.module';
import { CompanyModule } from './company/company.module';
import { ProductsModule } from './products/products.module';
import { ContactModule } from './contact/contact.module';

import { AppComponent } from './app.component';

import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { WrongRouteComponent } from './wrong-route/wrong-route.component';
import { PrivacyLandingComponent } from './privacy-landing/privacy-landing.component';
import { TermsLandingComponent } from './terms-landing/terms-landing.component';
import { FetosenseTermsPrivacyComponent } from './fetosense-terms-privacy/fetosense-terms-privacy.component';
import { BabybeatTermsPrivacyComponent } from './babybeat-terms-privacy/babybeat-terms-privacy.component';

import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { SitemapComponent } from './sitemap/sitemap.component';
import { CancelAndReturnComponent } from './cancel-and-return/cancel-and-return.component';

import { MaterialModule } from './shared/modules/material/material.module';

@NgModule({
  declarations: [
    AppComponent,
    TermsLandingComponent,
    PrivacyLandingComponent,
    WrongRouteComponent,
    FetosenseTermsPrivacyComponent,
    BabybeatTermsPrivacyComponent,
    SitemapComponent,
    CancelAndReturnComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HomeModule,
    CompanyModule,
    ProductsModule,
    ContactModule,
    AngularFireModule.initializeApp(environment.firebaseConf),
    AngularFirestoreModule,
    MaterialModule
  ],
  providers: [GoogleTagManagerService, {provide: 'googleTagManagerId',  useValue: 'GTM-PKDMP4D'}],
  bootstrap: [AppComponent]
})
export class AppModule {}
