<section>
    <div class="container-fluid inner-section">
        <div class="d-flex flex-lg-row flex-column container-inner">
            <div class="teams-container bg-color-bb">
                <h1 class="text-center text-lg-left text-white my-3" >
                    BellyBeat Stories
                </h1>
                <img alt="BabyBeat Fetal Monitor in India, CareNX, CareNX providing NST/CTG tests at home" src="../../../../../assets/images/products/babybeat/testimonials/babybeat-07.png" class="d-none d-lg-block">
            </div>
            <div class="glide-container">
                <div class="left-bg bg-color-bb"></div>
                <div class="glide glide__babybeat_testimonials">
                    <div class="glide__track" data-glide-el="track" >
                        <ul class="glide__slides">
                            <li class="glide__slide">
                                <div class="row h-100 card-container">
                                    <div class="col-12">
                                        <div class="card h-100 mb-3 mb-sm-none">
                                            <div class="card-inner h-100  d-flex flex-column justify-content-between">
                                                <p class="font-16 color-primary">
                                                    Your baby heart beat monitor was amazing. 
                                                    It is so easy to use without any hassle it connects via bluetooth. 
                                                    Heart rate is clearly monitored. 
                                                    The graph is so easy to share to the concerned doctors. 
                                                    Honestly your device saved my little one. 
                                                    Constant monitoring to the hospital was chaotic but your device made it so easy.
                                                    </p>
                                                <div>
                                                    <p class="font-16 color-bb-light">Renuka</p>
                                                    <p class="font-16  color-primary">(Mother)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </li>
                            <li class="glide__slide">
                                <div class="row h-100 card-container">
                                    <div class="col-12">

                                        <div class="card h-100 mb-3 mb-sm-none">
                                            <div class="card-inner h-100  d-flex flex-column justify-content-between">
                                                <p class="font-16 color-primary">
                                                    For NST reading the product is definitely very useful and convenient. 
                                                    Increased convenience during the covid times. 
                                                    Saviour for to-be-moms who have high risk condition.</p>
                                                <div>
                                                    <p class="font-16 color-bb-light">Dr. Aditi</p>
                                                    <p class="font-16 color-primary">ONP Hospital</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li class="glide__slide">
                                <div class="row h-100 card-container">
                                    <div class="col-12">
                                        <div class="card h-100 mb-3 mb-sm-none">
                                            <div class="card-inner h-100  d-flex flex-column justify-content-between">
                                                <p class="font-16 color-primary">
                                                    Mother illness was taking a toll on the baby and the nutrition she was receiving. 
                                                    CareNX's BellyBeat detected irregular fetal heart rate, 
                                                    which helped doctors in deciding next steps. 
                                                    Failing which the baby wouldn't have been in our arms.</p>
                                                <div>
                                                    <p class="font-16 color-bb-light">Arjun</p>
                                                    <p class="font-16  color-primary">(Father)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                    <div class="glide__arrows d-none d-lg-block" data-glide-el="controls">
                        <button class="glide__arrow glide__arrow--left" data-glide-dir="<" >
                            <i class="fa fa-angle-left" aria-hidden="true"></i>
                        </button>
                        <button class="glide__arrow glide__arrow--right" data-glide-dir=">" >
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="glide__bullets d-flex d-lg-none" data-glide-el="controls[nav]">
                        <button class="glide__bullet" data-glide-dir="=0"></button>
                        <button class="glide__bullet" data-glide-dir="=1"></button>
                        <button class="glide__bullet" data-glide-dir="=2"></button>
                    </div>
                </div>                    
            </div>
        </div>
    </div>
</section>