<section id="offering-section" class="bg-color-secondary-lighter">
    <div class="container inner-section">
        <div class="row mb-5" >
            <div class="col-12 text-center header">
                <p class="color-light mb-3">Our Offerings</p>
                <h1 class="color-secondary">Explore AI based women healthcare solutions</h1>
            </div>
        </div>
        <div class="row justify-content-center">
            
            <div class="product-container col-md-6 col-lg-3 mb-4 mb-lg-0">
                <a routerLink="/offering/fetosense">
                    <div class="card bg-color-secondary" >
                        <div class="card-body">
                            <div class="hover-title">
                                <h2 class="text-center text-white mt-3">Fetosense</h2>
                                <p class="card-price hr text-center text-white mb-3">AI based fetal NST/CTG machine</p>
                            </div>
                            <div class="hover-img">
                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="product-img" src="../../../../assets/images/home/products/fetosense.png">
                                <div class="product-container">
                                    <div class="product-details">
                                        <div class="details-inner mb-2">
                                            <div class="mr-2"><i class="fa fa-check color-secondary"></i></div>
                                            <span class="font-15 color-primary">Portable and wireless.</span>
                                        </div>
                                        <div class="details-inner mb-2">
                                            <div class="mr-2"><i class="fa fa-check color-secondary"></i></div>
                                            <span class="font-15 color-primary">Monitor test results from anywhere and anytime</span>
                                        </div>
                                        <div class="details-inner mb-2">
                                            <div class="mr-2"><i class="fa fa-check color-secondary"></i></div>
                                            <span class="font-15 color-primary">Unlimited test result storage with email & WhatsApp sharing</span>
                                        </div>
                                        <div class="details-inner mb-2">
                                            <div class="mr-2"><i class="fa fa-check color-secondary"></i></div>
                                            <span class="font-15 color-primary">Real-time notification for abnormal test result</span>
                                        </div>
                                    </div>
                                    <div class="intro-buttons mt-3">
                                        <a routerLink="/offering/fetosense" color="secondary" button-filled>
                                            Know More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>

            <div class="product-container col-md-6 col-lg-3 mb-4 mb-lg-0">
                <a routerLink="/offering/babybeat">
                    <div class="card bg-color-secondary" >
                        <div class="card-body">
                            <div class="hover-title">
                                <h2 class="text-center text-white mt-3">BellyBeat</h2>
                                <p class="card-price hr text-center text-white mb-3">Experience your baby's every beat</p>
                            </div>
                            <div class="hover-img">
                                <img alt="BabyBeat Fetal Monitor in India, CareNX, CareNX providing NST/CTG tests at home" class="product-img" src="../../../../assets/images/home/products/babybeat.png">
                                <div class="product-container">
                                    <div class="product-details">
                                        <div class="details-inner mb-2">
                                            <div class="mr-2"><i class="fa fa-check color-secondary"></i></div>
                                            <span class="font-15 color-primary">Smartphone based fetal heart monitor to perform NST (non-stress-test) at home.</span>
                                        </div>
                                        <div class="details-inner mb-2">
                                            <div class="mr-2"><i class="fa fa-check color-secondary"></i></div>
                                            <span class="font-15 color-primary">Medical grade DIY home monitoring solution for safe pregnancy.</span>
                                        </div>
                                        <div class="details-inner mb-2">
                                            <div class="mr-2"><i class="fa fa-check color-secondary"></i></div>
                                            <span class="font-15 color-primary">Real-time report sharing with your doctor.</span>
                                        </div>
                                    </div>
                                    <div class="intro-buttons mt-3">
                                        <a routerLink="/offering/babybeat" color="secondary" button-filled>
                                            Know More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="product-container col-md-6 col-lg-3 mb-4 mb-lg-0">
                <a routerLink="/offering/sindicolpo">
                    <div class="card bg-color-secondary" >
                        <div class="card-body">
                            <div class="hover-title">
                                <h2 class="text-center text-white mt-3">SindiColpo</h2>
                                <p class="card-price hr text-center text-white mb-3">AI based cervical cancer screening solution</p>
                            </div>
                            <div class="hover-img">
                                <img alt="CareNX, high risk pregnancy management platform, CareNX Anandimaa, Pregnancy care for rural mothers" class="product-img" src="../../../../assets/images/home/products/sindicolpo.png">
                                <div class="product-container">
                                    <div class="product-details">
                                        <div class="details-inner mb-2">
                                            <div class="mr-2"><i class="fa fa-check color-secondary"></i></div>
                                            <span class="font-15 color-primary">Empower healthcare providers.</span>
                                        </div>
                                        <div class="details-inner mb-2">
                                            <div class="mr-2"><i class="fa fa-check color-secondary"></i></div>
                                            <span class="font-15 color-primary">Public health partnership model to enable screening in remote areas.</span>
                                        </div>
                                        <div class="details-inner mb-2">
                                            <div class="mr-2"><i class="fa fa-check color-secondary"></i></div>
                                            <span class="font-15 color-primary">Partner with us to enable cerviacl screening into the remotest areas of India.</span>
                                        </div>
                                    </div>
                                    <div class="intro-buttons mt-3">
                                        <a routerLink="/offering/sindicolpo" color="secondary" button-filled>
                                            Know More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="product-container col-md-6 col-lg-3 mb-4 mb-lg-0">
                <a routerLink="/offering/anandimaa">
                    <div class="card bg-color-secondary" >
                        <div class="card-body">
                            <div class="hover-title">
                                <h2 class="text-center text-white mt-3">AnandiMaa</h2>
                                <p class="card-price hr text-center text-white mb-3">Pregnancy care for rural mothers</p>
                            </div>
                            <div class="hover-img">
                                <img alt="CareNX, high risk pregnancy management platform, CareNX Anandimaa, Pregnancy care for rural mothers" class="product-img" src="../../../../assets/images/home/products/anandimaa.png">
                                <div class="product-container">
                                    <div class="product-details">
                                        <div class="details-inner mb-2">
                                            <div class="mr-2"><i class="fa fa-check color-secondary"></i></div>
                                            <span class="font-15 color-primary">Empower healthcare providers.</span>
                                        </div>
                                        <div class="details-inner mb-2">
                                            <div class="mr-2"><i class="fa fa-check color-secondary"></i></div>
                                            <span class="font-15 color-primary">Public health partnership model to serve the needs of rural mothers.</span>
                                        </div>
                                        <div class="details-inner mb-2">
                                            <div class="mr-2"><i class="fa fa-check color-secondary"></i></div>
                                            <span class="font-15 color-primary">Partner with us to provide pregnancy care to remotest areas of India.</span>
                                        </div>
                                    </div>
                                    <div class="intro-buttons mt-3">
                                        <a routerLink="/offering/anandimaa" color="secondary" button-filled>
                                            Know More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>

            
        </div>
    </div>
    <div class="bg-color-secondary-lighter">
        <div class="container inner-section">
            <div class="row explore-container" >
                <div class="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-lg-between">
                    <h1 class="color-secondary text-center text-lg-left font-28 mb-4 mb-lg-0">
                        Our solutions have been tailor made for every <br>
                        hospital and doctor’s requirement.
                    </h1>
                    <a routerLink="/offering" color="secondary" button-filled>
                        View All Offerings</a>
                </div>
            </div>
        </div>
    </div>
</section>
