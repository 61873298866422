<div [class.isFixed]="isFixed">
    <app-main-navbar></app-main-navbar>
    <app-anandimaa-intro></app-anandimaa-intro>
    <app-anandimaa-counter></app-anandimaa-counter>
    <app-anandimaa-components></app-anandimaa-components>
    <app-anandimaa-stats></app-anandimaa-stats>
    <app-anandimaa-reach></app-anandimaa-reach>
    <app-anandimaa-testimonials></app-anandimaa-testimonials>
    <app-anandimaa-videos></app-anandimaa-videos>
    <app-anandimaa-enquiry></app-anandimaa-enquiry>
    <app-anandimaa-presense></app-anandimaa-presense>
    <app-anandimaa-locations></app-anandimaa-locations>
    <app-footer></app-footer>
</div>
