import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-career-why-join-us',
  templateUrl: './career-why-join-us.component.html',
  styleUrls: ['./career-why-join-us.component.scss']
})
export class CareerWhyJoinUsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
