<section>
    <div class="container inner-section" >
        <div class="row">
            <div class="col-12">
                <h1 class="text-center color-secondary">Locate Us</h1>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.846983559252!2d73.0116559147303!3d19.11436758706595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c08bf79e4fbb%3A0x4b6c7419b1bf53e6!2sCareNX%20Innovations%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1634545014068!5m2!1sen!2sin"
                style="border:0;" allowfullscreen=""></iframe>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="text-center">Greenscape Technocity, Ghansoli, Navi Mumbai,<br class="d-none d-md-block">
                    Maharashtra, India</p>
            </div>
        </div>
    </div>
</section>