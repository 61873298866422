import { Component, OnInit } from '@angular/core';
import Glide from '@glidejs/glide'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

@Component({
  selector: 'app-about-us-publications',
  templateUrl: './about-us-publications.component.html',
  styleUrls: ['./about-us-publications.component.scss']
})
export class AboutUsPublicationsComponent implements OnInit {

  showPublications:Boolean = false;
  constructor() { }

  ngOnInit() {
    new Glide('.glide__publications',{
      type: 'carousel',
      startAt: 0,
      perView: 4,
      breakpoints: {
        992: {
          perView: 3
        },
        768: {
          perView: 2
        },
        576: {
          perView: 1
        }
      }
    }).mount({ Controls, Breakpoints })
  }

  togglePublications(){
    this.showPublications = true;
  }

}
