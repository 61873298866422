import { AfterViewInit, Component, OnInit } from "@angular/core";
import { 
  Event, 
  Router, 
  NavigationStart, 
  NavigationEnd, 
  NavigationCancel, 
  NavigationError, 
  ActivatedRoute } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { filter, map } from 'rxjs/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

//import AOS from 'aos';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit, AfterViewInit {

  loader: boolean;
  loaderMain: boolean = true;

  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router,
    private route: ActivatedRoute,
    private gtmService: GoogleTagManagerService
  ) {
    this.loader, this.loaderMain = true;
    this.router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.loader = true;
      }
      if (routerEvent instanceof NavigationEnd ||
        routerEvent instanceof NavigationError ||
        routerEvent instanceof NavigationCancel) {
        setTimeout(() => {
          this.loader = false;
        }, 2000)
      }
    });
  
    if (this.router.url.includes('carenx')) {
      this.router.navigateByUrl('https://carenx.com/offering/anandimaa');
    }
    if (this.router.url.includes('anandimaa')) {
   
      this.router.navigateByUrl('https://carenx.com/offering/anandimaa');
    }
    if (this.router.url.includes('fetosense')) {
   
      this.router.navigateByUrl('https://carenx.com/offering/fetosense');
    }
    if (this.router.url.includes('babybeat')) {

      this.router.navigateByUrl('https://carenx.com/offering/babybeat');
    }
  
  }

  ngOnInit() {
    //AOS.init();
    // push GTM data layer for every visited page
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });

    /*let value = this.router.events.subscribe(evt => {
      
      if(typeof window !== undefined ){
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      }
      value.unsubscribe()
    });*/

    /*let routeValue = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(()=>{
      var route = this.getChild(this.route);

      route.data.subscribe(data => {
        this.title.setTitle(data.title);
        this.meta.updateTag({ name: 'description', content: data.description })
        this.meta.updateTag({ name: 'keywords', content: data.keywords })
      })
      routeValue.unsubscribe()
    })*/

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loaderMain = false;
      if(typeof document !== "undefined"){
        const body = document.getElementById("body");
        body.classList.remove("fixed_body");
      }
    }, 2000)
  }

  getChild(activaRoute: ActivatedRoute) {
    if (activaRoute.firstChild) {
      return this.getChild(activaRoute.firstChild)
    } else {
      return activaRoute
    }
  }

  customEvent() {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'button-click',
      data: 'my-custom-event',
    };
    this.gtmService.pushTag(gtmTag);
  }
}
