import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products-intro',
  templateUrl: './products-intro.component.html',
  styleUrls: ['./products-intro.component.scss']
})
export class ProductsIntroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
