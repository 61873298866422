import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anandimaa-stats',
  templateUrl: './anandimaa-stats.component.html',
  styleUrls: ['./anandimaa-stats.component.scss']
})
export class AnandimaaStatsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  scrollTo(){
    let element = document.getElementById('anandimaa-presense');
    element.scrollIntoView({behavior:'smooth'});
  }
}
