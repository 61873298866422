<div [class.isFixed]="isFixed">
    <app-main-navbar></app-main-navbar>
    <app-fetosense-intro></app-fetosense-intro>
    <app-fetosense-features></app-fetosense-features>
    <app-fetosense-components></app-fetosense-components>
    <app-fetosense-stats></app-fetosense-stats>
    <app-fetosense-testimonials></app-fetosense-testimonials>
    <app-fetosense-videos></app-fetosense-videos>
    <app-fetosense-enquiry></app-fetosense-enquiry>
    <app-fetosense-locations></app-fetosense-locations>
    <app-footer></app-footer>
</div>
