<section>    
    <div class="container  inner-section">
        <div class="row h-100">
            <div class="col-12">
                <div class="container-inner">
                    <a class="home-link mb-3" routerLink="/"> <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="./assets/img/logo.svg"> </a>
                    <h1 class="mb-3">404 error: Page not found</h1>
                    <h2 class="mb-3">It seems that you got lost, but we are here to help</h2>
                    <p>The page that you are looking for doesn’t exist on this website. You may have accidentally
                        mistyped the page address, or followed an expired link.<br> Anyway, we will help you get back on
                        track. Why don’t you try one of these pages for starters.</p>
                    <div class="link-btn mt-3">
                        <a class="text-center btn btn-large" role="button" routerLink="/">Pregnancy Care</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



