<section class="bg-color-secondary-lighter">
    <div class="container inner-section">
        <div class="row" >
            <div class="col-12 text-center color-secondary inner-container mb-5">
                <h1 class="color-secondary">Discover Free Demo Locations</h1>
            </div>
            <div class="col-12 inner-container-form">
                <form action="https://forms.gle/uv3Bf3iMDzGdPcBs9" target="_blank">
                    <div class="subscribe-form form-group">
                        <p class="text-center text-md-left mb-4 mb-md-0 mr-md-4">We're available in<br class="d-block d-md-none"> Mumbai | Pune | Nagpur | Bangalore | Gujarat and more</p>
                        <button type="submit" color="secondary" button-filled>
                            Get Free Demo
                        </button>
                    </div>
                </form>
            </div>
            <div class="col-sm-6 col-md-3 mb-4 mb-md-0">
                <div class="card-container">
                    <div [routerLink] = "['/company/about-us']" fragment="publications" class="card o-hidden h-100" >
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="card-img-top" src="../../../../../assets/images/products/fetosense/locations/working-laptop.jpg">
                        <div class="card-body">
                            <p class="font-15 color-light text-center">
                                Publications
                            </p>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="col-sm-6 col-md-3 mb-4 mb-md-0">
                <div class="card-container">
                    <div [routerLink] = "['/company/about-us']" fragment="awards&recognitions" class="card o-hidden h-100" >
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="card-img-top" src="../../../../../assets/images/products/fetosense/locations/champion-golden-trophy-winner-with-sport-player-hands-sport-stadium.jpg">
                        <div class="card-body">
                            <p class="font-15 color-light text-center">
                                Awards & Recognitions
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 mb-4 mb-md-0">
                <div class="card-container">
                    <div [routerLink] = "['/company/about-us']" fragment="supporters&partners" class="card o-hidden h-100" >
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="card-img-top" src="../../../../../assets/images/products/fetosense/locations/diverse-hands-united-business-teamwork-gesture.jpg">
                        <div class="card-body">
                            <p class="font-15 color-light text-center">
                                Supporters & Partners
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 mb-4 mb-md-0">
                <div class="card-container">
                    <div [routerLink] = "['/company/about-us']" fragment="newsroom" class="card o-hidden h-100" >
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="card-img-top" src="../../../../../assets/images/products/fetosense/locations/woman-reading-news-articles-mobile-phone.jpg">
                        <div class="card-body">
                            <p class="font-15 color-light text-center">
                                Newsroom
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>