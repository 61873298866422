import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OpeningsService } from '../../openings.service';
@Component({
  selector: 'app-career-openings',
  templateUrl: './career-openings.component.html',
  styleUrls: ['./career-openings.component.scss']
})
export class CareerOpeningsComponent implements OnInit {

  
  id: any;
  openingsList: any;
  showInnerDetails: boolean = false;
  showDetailsOnClick: boolean = false;
  noOpening: boolean = false;

  constructor(private route: ActivatedRoute, private openingsService:OpeningsService) {
    this.getOpenings();
  }

  ngOnInit() {
  }
  
  getOpenings(){
    let openingsData = this.openingsService.getOpenings().subscribe(data => {
      if(data.length < 1){
        this.noOpening = true;
        return
      }
      
      this.openingsList = data;
      this.noOpening = false;
      openingsData.unsubscribe();
    })
  }

  showDetails(){
    this.showDetailsOnClick = true;
  }
  hideDetails(){
    this.showInnerDetails = false;
  }

  scrollToOpenings(id){
    let element = document.getElementById(id);
    element.scrollIntoView();
  }

  async share(title, loc, exp){
    if (navigator && navigator['share']) {
      navigator['share']({
        title: 'CareNX Job Opening',
        text: 'Job Title: ' + title + '\n' + 
              'Location: ' + loc + '\n' + 
              'Experience: ' + exp  + '\n \n' +
              'Visit the following link to apply or for more details: ',
        url: location.href
      }) 
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      alert('share not supported');
    }
  }

}
