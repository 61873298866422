import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'CareNX Fetosense NST/CTG Machine (Fetal Monitor) in India',
      keywords: 'NST / CTG Machine in India, Fetal Monitor, Fetal Doppler, Portable CTG, Smart CTG, Homecare CTG, Fetosense, CareNX',
      description: 'CareNX Fetosense is a Smartphone based digital, wireless, portable NST/CTG machine, Fetal Monitor, Fetal Doppler Manufacturer in Pune, Mumbai, India. 8766531223'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
