import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: "about-us",
    loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule)
  },
  {
    path: "life-at-caremother",
    loadChildren: () => import('./life-caremother/life-caremother.module').then(m => m.LifeCaremotherModule)
  },
  {
    path: "careers",
    loadChildren: () => import('./career/career.module').then(m => m.CareerModule)
  },
  {
    path: "**",
    redirectTo: "/"
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule { }
