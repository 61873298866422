<section>
    <div class="container-fluid inner-section">
        <div class="d-flex flex-lg-row flex-column container-inner">
            <div class="teams-container bg-color-secondary-lighter">
                <h1 class="text-center text-lg-left color-secondary" >
                    AnandiMaa Stories
                </h1>
                <img alt="CareNX, high risk pregnancy management platform, CareNX Anandimaa, Pregnancy care for rural mothers" src="../../../../../assets/images/products/anandimaa/stats/anandimaa page-17.png" class="d-none d-lg-block">
            </div>
            <div class="glide-container">
                <div class="left-bg bg-color-bb"></div>
                <div class="glide glide__babybeat_testimonials">
                    <div class="glide__track" data-glide-el="track" >
                        <ul class="glide__slides">

                            <li class="glide__slide">
                                <div class="row h-100 card-container">
                                    <div class="col-12">
                                        <div class="card h-100 mb-3 mb-sm-none">
                                            <div class="card-inner h-100  d-flex flex-column justify-content-between">
                                                <p class="font-16 color-primary">
                                                    AnandiMaa incorporates not just the tests, 
                                                    but also the connected referral system for high-risk pregnancies, 
                                                    a service otherwise not easy available in an area 
                                                    like bhiwandi where our baseline survey showed only 38% 
                                                    women going for their pre-natal check-ups.
                                                    </p>
                                                <div>
                                                    <p class="font-16 color-secondary">Piyasree Mukherjee                                                    </p>
                                                    <p class="font-16  color-primary">ex-CEO, Foundation for mother and child health</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </li>
                            <li class="glide__slide">
                                <div class="row h-100 card-container">
                                    <div class="col-12">

                                        <div class="card h-100 mb-3 mb-sm-none">
                                            <div class="card-inner h-100  d-flex flex-column justify-content-between">
                                                <p class="font-16 color-primary">
                                                    AnandiMaa app has made my manual work of 
                                                    registration much easier and I can now utilise more time 
                                                    to understand the cause of lack of health seeking behavior 
                                                    amongst the women in my area and now I also try to educate them about it</p>
                                                <div>
                                                    <p class="font-16 color-secondary">Shanti Sharma</p>
                                                    <p class="font-16 color-primary">BMC health post (urban slum)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li class="glide__slide">
                                <div class="row h-100 card-container">
                                    <div class="col-12">
                                        <div class="card h-100 mb-3 mb-sm-none">
                                            <div class="card-inner h-100  d-flex flex-column justify-content-between">
                                                <p class="font-16 color-primary">
                                                    AnandiMaa kit has helped restore the trust 
                                                    and confidence of mother on us whenever we engage, 
                                                    to an extent that now small children 
                                                    from the community help us find the pregnant women 
                                                    in slum areas whenever they see us come with the kit!</p>
                                                <div>
                                                    <p class="font-16 color-secondary">Mansi Bisht</p>
                                                    <p class="font-16  color-primary">Thane, Kalwa, Niramaya health foundations</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                    <div class="glide__arrows d-none d-lg-block" data-glide-el="controls">
                        <button class="glide__arrow glide__arrow--left" data-glide-dir="<" >
                            <i class="fa fa-angle-left" aria-hidden="true"></i>
                        </button>
                        <button class="glide__arrow glide__arrow--right" data-glide-dir=">" >
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="glide__bullets d-flex d-lg-none" data-glide-el="controls[nav]">
                        <button class="glide__bullet" data-glide-dir="=0"></button>
                        <button class="glide__bullet" data-glide-dir="=1"></button>
                        <button class="glide__bullet" data-glide-dir="=2"></button>
                    </div>
                </div>                    
            </div>
        </div>
    </div>
</section>