<div>
    <!--<app-social-header></app-social-header>-->
    <app-main-navbar></app-main-navbar>
    <!--<app-fixed-socials></app-fixed-socials>-->
    <app-career-intro></app-career-intro>
    <!--<app-page-nav></app-page-nav>-->
    <app-career-benefits></app-career-benefits>
    <app-career-openings></app-career-openings>
    <app-career-why-join-us></app-career-why-join-us>
    <app-footer></app-footer>
</div>