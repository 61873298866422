<section>
    <div class="container inner-section">
        <div class="row">
            <div class="col-lg-5">
                <div class="details-container h-100 d-flex flex-column align-items-center align-items-lg-start justify-content-center" >
                    <h1 class="mb-4 color-secondary text-center text-lg-left">
                        How does AnandiMaa<br>
                        benefit different sectors?
                    </h1>
                    <button (click)="scrollTo()" class="d-none d-lg-block" color="secondary" button-outlined>See Our Presence</button>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="d-flex justify-content-center mb-3 mb-lg-0">
                    <img alt="CareNX, high risk pregnancy management platform, CareNX Anandimaa, Pregnancy care for rural mothers" class="d-none d-lg-block" id="path" src="../../../../../assets/images/products/anandimaa/stats/path.svg">
                    <img alt="CareNX, high risk pregnancy management platform, CareNX Anandimaa, Pregnancy care for rural mothers" class="d-none d-md-block d-lg-none" id="path" src="../../../../../assets/images/products/anandimaa/stats/path-horizontal.svg">
                </div>
            </div>
            <div class="col-lg-5">
                <div class="d-flex flex-column h-100 align-items-center">
                    <div class="row justify-content-center stats-container">
                        <div class="col-sm-6 col-md-4 col-lg-12">
                            <div class="d-flex h-100 align-items-center mb-4 mb-sm-0 mt-0">
                                <div class="stat-block d-flex flex-column flex-lg-row align-items-center first-img">
                                    <div class="img-block d-flex justify-content-center mb-3 mb-lg-0">
                                        <img alt="CareNX, high risk pregnancy management platform, CareNX Anandimaa, Pregnancy care for rural mothers" class="stat-img" src="../../../../../assets/images/products/anandimaa/stats/government.svg">
                                    </div>
                                    <div class="detail-container">
                                        <h2 class="text-center text-lg-left mb-2 mb-lg-0">Government</h2>
                                        <p class="text-center text-lg-left">50% Reduced Capital +<br>
                                            Operational Cost</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-12">
                            <div class="d-flex h-100 align-items-center mb-4 mb-sm-0">
                                <div class="stat-block d-flex flex-column flex-lg-row align-items-center">
                                    <div class="img-block d-flex justify-content-center mb-3 mb-lg-0">
                                        <img alt="CareNX, high risk pregnancy management platform, CareNX Anandimaa, Pregnancy care for rural mothers" class="stat-img" src="../../../../../assets/images/products/anandimaa/stats/hospital.svg">
                                    </div>
                                    <div class="detail-container">
                                        <h2 class="text-center text-lg-left mb-2 mb-lg-0">Hospital</h2>
                                        <p class="text-center text-lg-left">2x Increased Reach</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-12">
                            <div class="d-flex h-100 align-items-center mt-0 mt-sm-4">
                                <div class="stat-block d-flex flex-column flex-lg-row align-items-center last-img">
                                    <div class="img-block d-flex justify-content-center mb-3 mb-lg-0">
                                        <img alt="CareNX, high risk pregnancy management platform, CareNX Anandimaa, Pregnancy care for rural mothers" class="stat-img" src="../../../../../assets/images/products/anandimaa/stats/ngo.svg">
                                    </div>
                                    <div class="detail-container">
                                        <h2 class="text-center text-lg-left mb-2 mb-lg-0">NGO’s</h2>
                                        <p class="text-center text-lg-left">Better Performance +<br>
                                            Personalised Care & Digitized</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button (click)="scrollTo()" class="d-block d-lg-none mt-5 mt-lg-0" color="secondary" button-outlined>See Our Presence</button>
                </div>
            </div>
        </div>
    </div>
</section>