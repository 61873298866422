<section id="featured-section">
    <div class="container-fluid inner-section">
        <div class="d-flex flex-md-row flex-column-reverse justify-content-between align-items-center">
            <div class="row card-container">
                <div class="col-sm-6 mb-4">
                    <div class="card o-hidden h-100" >
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="card-img-top" src="./assets/images/landing/products/tinified/Awards-1.jpg">
                        <div class="card-body">
                            <p class="font-15">
                                Queen's Young Leader, Buckingham Palace, London UK
                            </p>
                        </div>
                    </div> 
                </div>
                <div class="col-sm-6 mb-4">
                    <div class="card o-hidden h-100" >
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="card-img-top" src="./assets/images/landing/products/tinified/Awards-2.jpg">
                        <div class="card-body">
                            <p class="font-15">
                                The President's Innovation Scholar, Delhi India
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 mb-4 mb-md-0">
                    <div class="card o-hidden h-100" >
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="card-img-top" src="./assets/images/landing/products/tinified/Awards-3.jpg">
                        <div class="card-body">
                            <p class="font-15">
                                Magnetic Maharashtra Award, Mumbai India
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 mb-4 mb-md-0">
                    <div class="card o-hidden h-100" >
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" class="card-img-top" src="./assets/images/landing/products/tinified/Awards-4.jpg">
                        <div class="card-body">
                            <p class="font-15">
                                UN Women Award, Singapore
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="details-container mb-4 mb-md-0" >
                <p class="color-light mb-3">Awards And Recognitions</p>
                <h1 class="color-secondary mb-3">
                    Our team has won many<br>
                    global awards and recognitions<br>
                </h1>
                <p class="color-primary mb-3">
                    Her Majesty The Queen of England - Elizabeth II, <br>
                    The Prime Minister Of India - Mr. Narendra Modi, <br>
                    UN Habitat, UN Women, CISCO, MIT (USA), Google, <br>
                    Vodafone, GCC, Skoll Foundation, etc.
                </p>
                <a class="d-none d-md-block" routerLink="/company/about-us"
                    color="secondary" button-filled>View More</a>         
            </div>
        </div>
        <div class="row d-block d-md-none mt-3" >
            <div class="col-12">
                <div class="d-flex" style="padding: 0 15px;">
                    <a routerLink="/company/about-us"
                        color="secondary" button-filled>View More</a>   
                </div>
            </div>
        </div>
    </div>
</section>