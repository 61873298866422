import { Component, OnInit } from '@angular/core';
import Glide from '@glidejs/glide'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

@Component({
  selector: 'app-employee-testimonials',
  templateUrl: './employee-testimonials.component.html',
  styleUrls: ['./employee-testimonials.component.scss']
})
export class EmployeeTestimonialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
    new Glide('.glide__testimonials_testimo',{
      type: 'carousel',
      startAt: 0,
      focusAt: 'center',
      perView: 3,
      peek: {
        before: -400,
        after: -400
      },
      gap: 20,
      breakpoints: {
        992: {
          perView: 1,
          peek: {
            before: 100,
            after: 100
          }
        },
        768: {
          perView: 1,
          peek: {
            before: 0,
            after: 0
          }
        }
      }
    }).mount({ Controls, Breakpoints })
  }

}
