import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { OpeningsService } from '../../openings.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from  '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-openings-card',
  templateUrl: './openings-card.component.html',
  styleUrls: ['./openings-card.component.scss']
})
export class OpeningsCardComponent implements OnInit {

  id: any;
  openingsDetails: any;
  isError: boolean = false;
  showDetails: boolean = false;
  createdOn: Date;
  resumeForm: UntypedFormGroup;

  private formValues = {
    'email': null,
    'name': null,
    'phone': null,
    'message': null,
    'subject': null,
    'apiKey': null,
  };
  location: any;
  type: any;

  constructor(private route: ActivatedRoute, private router: Router, private openingsService:OpeningsService, private fb: UntypedFormBuilder, private http: HttpClient) {
    let phoneNumber = "^[0-9]*$";
    let emailPattern = "^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    this.resumeForm = this.fb.group({
      resume: ['', Validators.required],
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      email: ['',[ 
        Validators.required,
        Validators.pattern(emailPattern),
      ]],
      mobile: ['',[
        Validators.required, 
        Validators.pattern(phoneNumber)
      ]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      pin: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.getOpeningsDetails();
  }
  getOpeningsDetails(){
    let openingsData = this.openingsService.getOpeningsDetails(this.id).subscribe(data => {
      console.log(data.length);
      if(data.length == 0){
        this.router.navigate(['/error-404']);
      }
      this.openingsDetails = data[0];
      this.createdOn =this.openingsDetails.createdOn.toDate();
      this.location = this.openingsDetails.location;
      this.type = this.openingsDetails.type;
      
      this.showDetails = true;
      openingsData.unsubscribe();
    })
  }

  apply(){

  }

  submitForm(){
    if(this.resumeForm.invalid){
      
    }else{
      this.formValues.email = this.resumeForm.value.email;
      this.formValues.name = this.resumeForm.value.fname + ' ' + this.resumeForm.value.lname;
      this.formValues.phone = this.resumeForm.value.mobile;

      this.formValues.message = 'Hello, I would like to apply for the ' + this.openingsDetails.title 
                                + ' position at CareNX, I am from ' + this.resumeForm.value.address
                                + ', ' + this.resumeForm.value.city + ', ' + this.resumeForm.value.state;
      this.formValues.subject = 'CareNX'
      this.formValues.apiKey = 'pB9DJrDNaAOWiPoS9bBT';

      console.log('FORM VALUES :',this.formValues)

      this.http.post('https://caremother.co:3000/api/sendInquiries', this.formValues).subscribe((data) => {
        
          Swal.fire({
            html:'<h1 class="swal-title">Your Application Is Sent Successfully</h1>',
            //text: "You have successfully subscribed to our list. You'll be receiving a confirmation email soon. Now, you can stay up-to-date with all the CareNX news.",
            icon: 'success',
            width: '50rem',
            iconColor: '#00A69A',
            confirmButtonColor: '#00A69A',
            confirmButtonText: 'Go Back',
            focusConfirm: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false
          })


          this.formValues.email = null;
          this.formValues.name = null;
          this.formValues.phone = null;
          this.formValues.subject = null;
          this.formValues.message = null;
        },
        (error: HttpErrorResponse) => {
          console.log(error, 'data');
        }
      )
    }
  }

  async share(){
    if (navigator && navigator['share']) {
      navigator['share']({
        title: 'CareNX Job Opening',
        text: 'Job Title: ' + this.openingsDetails.title + '\n' + 
              'Location: ' + this.openingsDetails.location + '\n' + 
              'Experience: ' + this.openingsDetails.experience  + '\n \n' +
              'Visit the following link to apply or for more details: ',
        url: window.location.href
      }) 
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      alert('share not supported');
    }
  }

}
