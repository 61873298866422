<section id="openings-section">
    <div class="section-bg">
        <p class="text-center text-white" >CareNX Innovations Pvt. Ltd.</p>
        <hr>
        <h1 class="text-center text-white" *ngIf="showDetails" >Job Role: {{openingsDetails.title}}</h1>
        <div class="bottom-details d-flex flex-column flex-sm-row mt-2" >
            <p class="font-12 text-white">Location: {{location}}</p>
            <p class="font-12 text-white mx-2 px-2 middle">Type: {{type}}</p>
            <p class="font-12 text-white">Posted On: {{createdOn | date:'mediumDate'}}</p>
        </div>
    </div>
    <div class="application">
        <div class="container inner-section">
            <div class="row">
                <div class="col-12">
                    <div class="card-block" *ngIf="showDetails">
                        <h1 class="font-weight-bold font-26 mt-5 color-primary">Apply for this position</h1>
                        <small>*Required Field</small>
                        <form class="row form" [formGroup]="resumeForm" role="form" (ngSubmit)="submitForm()">
                            <div class="form-group file-input mt-4 col-md-8">
                                <label for="resume" class="text-center bg-color-secondary-lighter">
                                    Upload your resume or drag and drop it here <br>
                                    Only .doc, .docx, .pdf, .odt, .rtf format
                                </label>
                                <input type="file" id="resume" class="inputfile" formControlName="resume"
                                accept=".doc,.docx,.odt,.rtf,.pdf" ngModel required>
                            </div>
                            <div class="other-apply mt-4 col-md-4">
                                <a class="w-100" href="https://www.linkedin.com/company/fetosense%C2%AE/" target="_blank"
                                    color="secondary" button-outlined>Apply with Linkedin</a>
                                <button (click)="share()" class="mt-3 w-100"
                                    color="secondary" button-outlined>Share Job</button>
                            </div>
                            <div class="form-group mt-4 col-md-6">
                                <label for="fname">First Name *</label>
                                <input type="text" id="fname" class="form-control form-control-lg"
                                formControlName="fname" ngModel required>
                            </div>
                            <div class="form-group mt-4 col-md-6">
                                <label for="lname">Last Name *</label>
                                <input type="text" id="lname" class="form-control form-control-lg"
                                formControlName="lname" ngModel required>
                            </div>
                            <div class="form-group mt-4 col-sm-6">
                                <label for="email">Email Address *</label>
                                <input type="email" email ngModel class="form-control form-control-lg"
                                formControlName="email" id="email" required>
                            </div>
                            <div class="form-group mt-4 col-sm-6">
                                <label for="mobile">Mobile No.*</label>
                                <input type="phone" minlength="10" maxlength="10" ngModel class="form-control form-control-lg"
                                formControlName="mobile" id="mobile" required>
                            </div>
                            <div class="form-group mt-4 col-sm-12">
                                <label for="address">Address</label>
                                <input type="text" ngModel class="form-control form-control-lg"
                                formControlName="address" id="address" required>
                            </div>
                            <div class="form-group mt-4 col-sm-4">
                                <label for="city">City</label>
                                <input type="text" ngModel class="form-control form-control-lg"
                                formControlName="city" id="city" required>
                            </div>
                            <div class="form-group mt-4 col-sm-4">
                                <label for="state">State</label>
                                <input type="text" ngModel class="form-control form-control-lg"
                                formControlName="state" id="state" required>
                            </div>
                            <div class="form-group mt-4 col-sm-4">
                                <label for="pin">PIN Code</label>
                                <input type="text" ngModel class="form-control form-control-lg"
                                formControlName="pin" id="pin" required>
                            </div>
                            
                            <div class="form-group d-flex flex-column flex-md-row mt-3 col-12 col-md-6">
                                <button type="submit" [disabled]="!resumeForm.valid" class="mr-3 mb-3 mb-md-0 w-100 w-md-auto"
                                    color="secondary" button-filled>Submit Application</button>
                                <button type="reset" class="w-100 w-md-auto"
                                    color="secondary" button-outlined>Reset</button>
                            </div>
                            <div class="form-group mt-3 col-12 col-md-6 d-flex justify-content-end align-items-center">
                                <p>&#8592; Go back to <a class="color-secondary" [routerLink] = "['/company/careers']" fragment="openings">Career’s Page</a></p>
                            </div>
                        </form>
                    </div>
                </div>   
            </div>
        </div>
    </div>
</section>