import { Component, OnInit } from '@angular/core';
import Glide from '@glidejs/glide'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'
@Component({
  selector: 'app-life-team',
  templateUrl: './life-team.component.html',
  styleUrls: ['./life-team.component.scss']
})
export class LifeTeamComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    new Glide('.glide__testimonials_team',{
      type: 'slider',
      startAt: 0,
      perView: 1,
      gap: 0,
      hoverpause: false,
    }).mount({ Controls, Breakpoints })

  }

}
