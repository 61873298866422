import { NgModule } from "@angular/core";
import { Routes, RouterModule } from '@angular/router';

import { HomecareComponent } from "./homecare.component";


const routes: Routes = [

  {
    path: "",
    component: HomecareComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomecareRoutingModule { }
