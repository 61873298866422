<section>
    <div class="msg-container inner-section bg-color-primary-light">
        <div class="container-fluid">
            <div class="row" >
                <div class="col-md-6">
                    <div>
                        <p class="text-white mb-3">Well, Hello!</p>
                        <h1 class="text-white">Feel free to get in touch and our team<br class="d-none d-lg-block">
                            will be happy to assist you.</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="details-container inner-section">
        <div class="container-fluid">
            <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 contact-details" >
                    <div class="contact-details-inner">
                        <div class="details-content">
                            <p class="color-secondary mb-2">Got Questions?</p>
                            <a href="tel:+919326775598"><h1 class="text-white font-25">+91 93267 75598</h1></a>
                        </div>
                        <div class="details-content details-middle">
                            <p class="color-secondary mb-2">Looking For?</p>
                            <h1 class="text-white font-25 d-sm-flex mb-2">Support -<a href="mailto:sales@carenx.com"><h1 class="text-white font-25 mt-2 mt-sm-0 ml-sm-2">sales@carenx.com</h1></a></h1>
                            <h1 class="text-white font-25 d-sm-flex">Enquiry -<a href="mailto:info@carenx.com"><h1 class="text-white font-25 mt-2 mt-sm-0 ml-sm-2">info@carenx.com</h1></a></h1>
                        </div>
                        <div class="details-content">
                            <p class="color-secondary mb-2">Contact to a Expert?</p>
                            <a href="https://forms.gle/uv3Bf3iMDzGdPcBs9" target="_blank"
                                color="white" button-filled>
                                    Connect Now</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 contact-form" >
                    <div class="contact-form-inner bg-color-white">
                        <h1 class="color-primary">Contact Us</h1>
                        <form class="row form" [formGroup]="contactForm" role="form" (ngSubmit)="submitForm()">
                            <div class="form-group mt-4 col-md-12">
                                <input type="text" class="form-control form-control-lg" placeholder="Name *"
                                formControlName="name" ngModel required>
                            </div>
                            <div class="form-group mt-4 col-sm-6">
                                <input type="email" email ngModel class="form-control form-control-lg"
                                formControlName="email" placeholder="Email *" required>
                            </div>
                            <div class="form-group mt-4 col-sm-6">
                                <input type="phone" minlength="10" maxlength="10" ngModel class="form-control form-control-lg"
                                formControlName="mobile" placeholder="Mobile Number *" required>
                            </div>
                            <div class="form-group mt-4 col-sm-12">
                                <select class="form-control form-control-lg" 
                                formControlName="profession" placeholder="Profession *" required>
                                    <option value="" selected>Profession *</option>
                                    <option value="Doctor">Doctor</option>
                                    <option value="Mother">Mother</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <!--<div class="form-group mt-4 col-sm-6">
                                <select class="form-control form-control-lg" 
                                formControlName="product" required>
                                    <option value="" selected>Select Product *</option>
                                    <option value="BabyBeat">BabyBeat</option>
                                    <option value="FetoSense">FetoSense</option>
                                    <option value="AnandiMaa">AnandiMaa</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>-->
                            <div class="form-group mt-4 col-md-12">
                                <textarea class="form-control form-control-lg" placeholder="Your message here (optional)"
                                formControlName="message" rows="5"></textarea>
                            </div>
                            <div *ngIf="isError" class="alert alert-warning my-2" role="alert">
                                {{errorMsg}}
                            </div>
                            <div class="form-group mt-3 col-md-12">
                                <button type="submit" [disabled]="!contactForm.valid" class="w-100" color="secondary" button-filled>Send Message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>