<div class="container inner-section">
    <div class="row pt-4">
        <div class="col-12">
            <h4 class="text-center">Refund Policy</h4>
        </div>
    </div>
    <div class="row pt-4">
        <div class="col-12">
            <h4>Cancellation </h4>
        </div>
        <div class="col-12 text-justify">
            Cancellation of any product would require connecting with our support staff with proper reasoning for cancellation. 
            <br><br>
            To be eligible for cancellation, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
            <br><br>
            If approved, the refund of purchase amount would be done within next 30 days from the date of cancellation and receipt of return of the product. Please note that annual maintenance cost or comprehensive maintenance contract or support service charges are not cancellable. 

        </div>

    </div>

    <div class="row pt-4">
        <div class="col-12">
            <h4>Returns</h4>
        </div>
        <div class="col-12 text-justify">
            Our policy lasts 30 days. If 30 days have gone by since your purchase and receipt of the product, unfortunately we can’t offer you a refund or exchange.
            <br><br>
            To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
            <br><br>
            Several types of goods are exempt from being returned. Perishable goods such as food, flowers, newspapers or magazines cannot be returned. We also do not accept products that are intimate or sanitary goods, hazardous materials, or flammable liquids or gases.

        </div>
    </div>


    <div class="row pt-3">
        <div class="col-12 text-justify">
            Additional non-returnable items:
        </div>
        
        <ul>
            <li class="text-justify pt-2">
                Gift cards
            </li>
            <li class="text-justify pt-2">
                Downloadable software products

            </li>
            <li class="text-justify pt-2"> 
                Some health and personal care items
            </li>
        </ul>
    </div>


    <div class="row pt-4">
        <div class="col-12 text-justify">
            To complete your return, we require a receipt or proof of purchase. Please do not send your purchase back to the distributor.
            <br><br>
            There are certain situations where only partial refunds are granted: (if applicable)
            Product with obvious signs of use, missing components, damaged components, physical breakage or damage and product which has been opened for use.
            Any item not in its original condition, is damaged or missing parts for reasons not due to our error. Any item that is returned more than 30 days after delivery
            
        </div>
    </div>




    <div class="row pt-4">
        <div class="col-12">
            <h4>Refunds (if applicable)</h4>
        </div>
        <div class="col-12 text-justify">
            Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.
            <br><br>
            If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days. 


        </div>
    </div>


    <div class="row pt-4">
        <div class="col-12">
            <h4>Late or missing refunds (if applicable)
            </h4>
        </div>
        <div class="col-12 text-justify">
            If you haven’t received a refund yet, first check your bank account again.
            Then contact your credit card company, it may take some time before your refund is officially posted.
            Next contact your bank. There is often some processing time before a refund is posted.
            If you’ve done all of this and you still have not received your refund yet, please contact us at <a href="mailto:support@carenx.com"><b>[support@carenx.com]</b></a>.
        </div>
    </div>


    <div class="row pt-4">
        <div class="col-12">
            <h4>Sale items (if applicable)</h4>
        </div>
        <div class="col-12 text-justify">
            Only regular priced items may be refunded, unfortunately sale items cannot be refunded.
        </div>
    </div>


    <div class="row pt-4">
        <div class="col-12">
            <h4>Exchanges (if applicable)</h4>
        </div>
        <div class="col-12 text-justify">
            We only replace items if they are defective or damaged.  If you need to exchange it for the same item, send us an email at <a href="mailto:support@carenx.com"><b>[support@carenx.com]</b></a> and send your item to: 106, First Floor, A-Wing, Greenscape Technocity, Shilphata - Mahape Road, Near Country Inn Hotel, MIDC Industrial Area, Ghansoli, Navi Mumbai, Maharashtra, 400710 India
        </div>
    </div>


    <div class="row pt-4 pb-3">
        <div class="col-12">
            <h4>Gifts</h4>
        </div>
        <div class="col-12 text-justify">
            If the item was marked as a gift when purchased and shipped directly to you, you’ll receive a gift credit for the value of your return. Once the returned item is received, a gift certificate will be mailed to you.
            <br><br>
            If the item wasn’t marked as a gift when purchased, or the gift giver had the order shipped to themselves to give to you later, we will send a refund to the gift giver and he will find out about your return.

        </div>
    </div>


    <div class="row pt-4 pb-3">
        <div class="col-12">
            <h4>Shipping</h4>
        </div>
        <div class="col-12 text-justify">
            To return your product, you should mail your product to: 106, First Floor, A-Wing, Greenscape Technocity, Shilphata - Mahape Road, Near Country Inn Hotel, MIDC Industrial Area, Ghansoli, Navi Mumbai, Maharashtra, 400710 India.
            <br><br>
            You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.
            <br><br>
            Depending on where you live, the time it may take for your exchanged product to reach you, may vary.
            <br><br>
            You should also consider using a trackable shipping service or purchasing shipping insurance. We don’t guarantee that we will receive your returned item.

        </div>
    </div>
</div>