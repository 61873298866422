import { NgModule } from "@angular/core";
import { Routes, RouterModule } from '@angular/router';

import { SindiColpoComponent } from "./sindicolpo.component";


const routes: Routes = [

  {
    path: "",
    component: SindiColpoComponent,
    data: {
      title: 'SindiColpo | AI based cervical cancer screening solution',
      keywords: 'Colposcopy, Cervical cancer, AI, CareNX',
      description: "SindiColpo is a smartphone based colposcopy machine. Wireless | Portable | Remote Monitoring."
    }
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SindiColpoRoutingModule { }
