import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BabybeatLandingRoutingModule } from './babybeat-landing-routing.module';
import { BabybeatComponent } from './babybeat.component';
import { NgSelectModule } from '@ng-select/ng-select';

import { ComponentsModule } from 'src/app/shared/components/components.module';
import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { BabybeatIntroComponent } from './components/babybeat-intro/babybeat-intro.component';
import { BabybeatFeaturesComponent } from './components/babybeat-features/babybeat-features.component';
import { BabybeatComponentsComponent } from './components/babybeat-components/babybeat-components.component';
import { BabybeatStatsComponent } from './components/babybeat-stats/babybeat-stats.component';
import { BabybeatTestimonialsComponent } from './components/babybeat-testimonials/babybeat-testimonials.component';
import { BabybeatVideosComponent } from './components/babybeat-videos/babybeat-videos.component';
import { BabybeatEnquiryComponent } from './components/babybeat-enquiry/babybeat-enquiry.component';
import { BabybeatLocationsComponent } from './components/babybeat-locations/babybeat-locations.component';
import { BabybeatFaqComponent } from './components/babybeat-faq/babybeat-faq.component';

@NgModule({
  declarations: [BabybeatComponent, BabybeatIntroComponent, BabybeatFeaturesComponent, BabybeatComponentsComponent, BabybeatStatsComponent, BabybeatTestimonialsComponent, BabybeatVideosComponent, BabybeatEnquiryComponent, BabybeatLocationsComponent, BabybeatFaqComponent],
  imports: [
    CommonModule,
    BabybeatLandingRoutingModule,
    ComponentsModule,
    MaterialModule,
    NgSelectModule
  ]
})
export class BabybeatModule { }
