<div [class.isFixed]="isFixed">
    <app-main-navbar></app-main-navbar>
    <app-sindicolpo-intro></app-sindicolpo-intro>
    <app-sindicolpo-features></app-sindicolpo-features>
    <app-sindicolpo-components></app-sindicolpo-components>
    <app-sindicolpo-stats></app-sindicolpo-stats>
    <app-sindicolpo-testimonials></app-sindicolpo-testimonials>
    <app-sindicolpo-videos></app-sindicolpo-videos>
    <app-sindicolpo-enquiry></app-sindicolpo-enquiry>
    <app-sindicolpo-locations></app-sindicolpo-locations>
    <app-footer></app-footer>
</div>
