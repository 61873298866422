<section>
    <div class="container inner-section">
        <div class="row mb-5">
            <div class="col-12 text-center">
                <h1>Impacts and Outcomes</h1>
                <p>We are committed to transforming the lives of our users and patients</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="glide-container">
                    <div class="glide glider">
                        <div class="glide__track" data-glide-el="track">
                        <ul class="glide__slides">
                            <li class="glide__slide"></li>
                            <li class="glide__slide"></li>
                            <li class="glide__slide"></li>
                        </ul>
                        </div>
                        <div class="glide__arrows" data-glide-el="controls">
                            <button class="glide__arrow glide__arrow--left" data-glide-dir="<" >
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                            </button>
                            <button class="glide__arrow glide__arrow--right" data-glide-dir=">" >
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>