import { Component, HostListener, OnInit } from '@angular/core';

import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-babybeat',
  templateUrl: './babybeat.component.html',
  styleUrls: ['./babybeat.component.scss']
})
export class BabybeatComponent implements OnInit {
  constructor(
    private title: Title,
    private meta: Meta) {
      
    this.title.setTitle('BabyBeat Fetal Monitor in India');
    this.meta.updateTag({ name: 'description', content: "Monitor your baby's heartbeat anytime, anywhere with CareNX BabyBeat now 8766531223" })
    this.meta.updateTag({ name: 'keywords', content: "BabyBeat Fetal Monitor in India, CareNX, CareNX providing NST/CTG tests at home" })
    }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  isFixed: boolean;
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if(typeof window !== undefined ){
          
      const offset =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (offset > 400) {
        this.isFixed = true;
      } else if(offset <= 500){
        this.isFixed = false
      }
      else {
        this.isFixed = false;
      }
    }
  }
}
