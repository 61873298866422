import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-anandimaa-videos',
  templateUrl: './anandimaa-videos.component.html',
  styleUrls: ['./anandimaa-videos.component.scss']
})
export class AnandimaaVideosComponent implements OnInit {
  safeURL: any;

  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/yqT7l9l3OMY');
  }

}
