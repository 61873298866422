import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AnandimaaComponent } from './anandimaa.component';
import { AnandimaaRoutingModule } from './anandimaa.routing';

import { NgSelectModule } from '@ng-select/ng-select';

import { ComponentsModule } from 'src/app/shared/components/components.module';
import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { AnandimaaIntroComponent } from './components/anandimaa-intro/anandimaa-intro.component';
import { AnandimaaCounterComponent } from './components/anandimaa-counter/anandimaa-counter.component';
import { AnandimaaComponentsComponent } from './components/anandimaa-components/anandimaa-components.component';
import { AnandimaaStatsComponent } from './components/anandimaa-stats/anandimaa-stats.component';
import { AnandimaaTestimonialsComponent } from './components/anandimaa-testimonials/anandimaa-testimonials.component';
import { AnandimaaVideosComponent } from './components/anandimaa-videos/anandimaa-videos.component';
import { AnandimaaEnquiryComponent } from './components/anandimaa-enquiry/anandimaa-enquiry.component';
import { AnandimaaLocationsComponent } from './components/anandimaa-locations/anandimaa-locations.component';
import { AnandimaaPresenseComponent } from './components/anandimaa-presense/anandimaa-presense.component';
import { AnandimaaReachComponent } from './components/anandimaa-reach/anandimaa-reach.component';

@NgModule({
  declarations: [AnandimaaComponent, AnandimaaIntroComponent, AnandimaaCounterComponent, AnandimaaComponentsComponent, AnandimaaStatsComponent, AnandimaaTestimonialsComponent, AnandimaaVideosComponent, AnandimaaEnquiryComponent, AnandimaaLocationsComponent, AnandimaaPresenseComponent, AnandimaaReachComponent],
  imports: [
    CommonModule,
    AnandimaaRoutingModule,
    ComponentsModule,
    MaterialModule,
    NgSelectModule
  ]
})
export class AnandimaaModule { }
