import { Component, OnInit, HostListener, AfterContentInit } from '@angular/core';


@Component({
  selector: 'app-rolling-counter',
  templateUrl: './rolling-counter.component.html',
  styleUrls: ['./rolling-counter.component.scss']
})
export class RollingCounterComponent implements OnInit, AfterContentInit {

  motherCount:number = 0;
  hospitalCount:number = 0;
  stateCount:number = 0;
  countryCount:number = 0;

  countryCountStop: any;
  stateCountStop: any;
  hospitalCounterStop: any;
  motherCounterStop: any;

  constructor() { }
  ngAfterContentInit(): void {
    var target = document.getElementById('rolling-counter');
    console.log(target.offsetTop + 130, window.innerHeight)
    if(window.innerHeight >= target.offsetTop){
      if(this.motherCount != 500000 && this.motherCount < 499000){
        this.motherCount = 499000;
      }else if(this.motherCount == 500000){
        this.motherCount = 500000;
      }

      this.motherCounterStop = setInterval(()=>{
        
        if(this.motherCount < 500000){
          this.motherCount++;
        }
        if(this.motherCount >= 500000){
          clearInterval(this.motherCounterStop);
        }
      },50)
      
      this.hospitalCounterStop = setInterval(()=>{
        
        if(this.hospitalCount < 1000){
          this.hospitalCount++;
        }
        if(this.hospitalCount >= 1000){
          clearInterval(this.hospitalCounterStop);
        }
      },50)
      
      this.stateCountStop = setInterval(()=>{
        if(this.stateCount < 20){
          this.stateCount++;
        }
        
        if(this.stateCount >= 20){
          clearInterval(this.stateCountStop);
        }
      },200)

      this.countryCountStop = setInterval(()=>{
        if(this.countryCount < 6){
          this.countryCount++;
        }
        if(this.countryCount >= 6){
          clearInterval(this.countryCountStop);
        }
      },300)
    }
  }

  ngOnInit() { }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if(typeof window !== undefined ){
        
      var target = document.getElementById('rolling-counter');
      const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    
      if(offset >= target.offsetHeight && offset <= target.offsetTop) {

        if(this.motherCount != 500000 && this.motherCount < 499000){
          this.motherCount = 499000;
        }else if(this.motherCount == 500000){
          this.motherCount = 500000;
        }

        this.motherCounterStop = setInterval(()=>{
          
          if(this.motherCount < 500000){
            this.motherCount++;
          }
          if(this.motherCount >= 500000){
            clearInterval(this.motherCounterStop);
          }
        },50)
        
        this.hospitalCounterStop = setInterval(()=>{
          
          if(this.hospitalCount < 1000){
            this.hospitalCount++;
          }
          if(this.hospitalCount >= 1000){
            clearInterval(this.hospitalCounterStop);
          }
        },50)
        
        this.stateCountStop = setInterval(()=>{
          if(this.stateCount < 20){
            this.stateCount++;
          }
          
          if(this.stateCount >= 20){
            clearInterval(this.stateCountStop);
          }
        },200)

        this.countryCountStop = setInterval(()=>{
          if(this.countryCount < 6){
            this.countryCount++;
          }
          if(this.countryCount >= 6){
            clearInterval(this.countryCountStop);
          }
        },300)
      }
    }
  }

}
