import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fetosense-stats',
  templateUrl: './fetosense-stats.component.html',
  styleUrls: ['./fetosense-stats.component.scss']
})
export class FetosenseStatsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
