import { Component, HostListener, OnInit, AfterContentInit } from '@angular/core';

@Component({
  selector: 'app-anandimaa-counter',
  templateUrl: './anandimaa-counter.component.html',
  styleUrls: ['./anandimaa-counter.component.scss']
})
export class AnandimaaCounterComponent implements OnInit, AfterContentInit {

  visitsCount:number = 0;
  mothersCount:number = 0;
  villagesCount:number = 0;
  statesCount:number = 0;
  partnersCount:number = 0;

  visitsCountStop: any;
  mothersCountStop: any;
  villagesCountStop: any;
  statesCountStop: any;
  partnersCountStop: any;

  constructor() { }
  ngAfterContentInit(): void {
    var target = document.getElementById('anandimaa-counter');
    console.log(target.offsetTop + 130, window.innerHeight)
    if(window.innerHeight >= target.offsetTop + 130){
      if(this.visitsCount != 200000 && this.visitsCount < 199701){
        this.visitsCount = 199700;
      }else if(this.visitsCount == 200000){
        this.visitsCount = 200000;
      }

      this.visitsCountStop = setInterval(()=>{
        
        if(this.visitsCount < 200000){
          this.visitsCount++;
        }
        if(this.visitsCount >= 200000){
          clearInterval(this.visitsCountStop);
        }
      },50)

      if(this.mothersCount != 100000 && this.mothersCount < 99701){
        this.mothersCount = 99700;
      }else if(this.mothersCount == 100000){
        this.mothersCount = 100000;
      }

      this.mothersCountStop = setInterval(()=>{
        
        if(this.mothersCount < 100000){
          this.mothersCount++;
        }
        if(this.mothersCount >= 100000){
          clearInterval(this.mothersCountStop);
        }
      },50)
      
      this.villagesCountStop = setInterval(()=>{
        
        if(this.villagesCount < 1000){
          this.villagesCount++;
        }
        if(this.villagesCount >= 1000){
          clearInterval(this.villagesCountStop);
        }
      },20)
      
      this.statesCountStop = setInterval(()=>{
        if(this.statesCount < 12){
          this.statesCount++;
        }
        
        if(this.statesCount >= 12){
          clearInterval(this.statesCountStop);
        }
      },200)

      this.partnersCountStop = setInterval(()=>{
        if(this.partnersCount < 15){
          this.partnersCount++;
        }
        if(this.partnersCount >= 15){
          clearInterval(this.partnersCountStop);
        }
      },200)
    }
  }

  ngOnInit() { }
  
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if(typeof window !== undefined ){
        
      var target = document.getElementById('anandimaa-counter');
      const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    
      if(offset >= target.offsetHeight/2 && offset <= target.offsetTop) {

        if(this.visitsCount != 200000 && this.visitsCount < 199701){
          this.visitsCount = 199700;
        }else if(this.visitsCount == 200000){
          this.visitsCount = 200000;
        }

        this.visitsCountStop = setInterval(()=>{
          
          if(this.visitsCount < 200000){
            this.visitsCount++;
          }
          if(this.visitsCount >= 200000){
            clearInterval(this.visitsCountStop);
          }
        },50)

        if(this.mothersCount != 100000 && this.mothersCount < 99701){
          this.mothersCount = 99700;
        }else if(this.mothersCount == 100000){
          this.mothersCount = 100000;
        }

        this.mothersCountStop = setInterval(()=>{
          
          if(this.mothersCount < 100000){
            this.mothersCount++;
          }
          if(this.mothersCount >= 100000){
            clearInterval(this.mothersCountStop);
          }
        },50)
        
        this.villagesCountStop = setInterval(()=>{
          
          if(this.villagesCount < 1000){
            this.villagesCount++;
          }
          if(this.villagesCount >= 1000){
            clearInterval(this.villagesCountStop);
          }
        },5)
        
        this.statesCountStop = setInterval(()=>{
          if(this.statesCount < 12){
            this.statesCount++;
          }
          
          if(this.statesCount >= 12){
            clearInterval(this.statesCountStop);
          }
        },200)

        this.partnersCountStop = setInterval(()=>{
          if(this.partnersCount < 15){
            this.partnersCount++;
          }
          if(this.partnersCount >= 15){
            clearInterval(this.partnersCountStop);
          }
        },200)
      }
    }
  }

}
