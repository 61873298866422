import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomecareComponent } from "./homecare.component";
import { HomecareRoutingModule } from './homecare.routing';

@NgModule({
  declarations: [HomecareComponent],
  imports: [
    CommonModule,
    HomecareRoutingModule
  ]
})
export class HomecareModule { }
