<section>
    <div class="left-bg  bg-color-bb-light d-none d-lg-block">
        <img alt="BabyBeat Fetal Monitor in India, CareNX, CareNX providing NST/CTG tests at home" src="../../../../../assets/images/products/babybeat/stats/babybeat page-17.png">
    </div>
    <div class="container-fluid inner-section">
        <div class="row align-items-center">
            <div class="col-12">
                <div class="inner-block d-flex flex-column-reverse flex-lg-row justify-content-between">
                    
                    <div class="details-container d-flex flex-column justify-content-center mb-4 mb-md-0" >
                        
                        <div class="mb-5 d-none d-lg-block">
                            <h1 class="color-bb-secondary">Experience the joy of your baby’s every<br class="d-none d-xl-block">
                                beat in three easy steps</h1>
                        </div>
                        <div class="d-flex flex-column flex-sm-row justify-content-sm-around justify-content-lg-between">
                            <div class="stats mb-5 mb-sm-0 d-flex flex-sm-column align-items-center">
                                <img alt="BabyBeat Fetal Monitor in India, CareNX, CareNX providing NST/CTG tests at home" class="mb-3 mr-sm-0 mr-4" src="../../../../../assets/images/products/babybeat/stats/hear.svg">
                                <div>
                                    <h2 class="color-primary mb-2 text-sm-center">Hear</h2>
                                    <p class="color-primary font-13 text-sm-center">Hear your baby’s heartbeat,<br class="d-none d-sm-block">
                                        anywhere, at anytime</p>
                                </div>
                            </div>
                            <div class="stats mb-5 mb-sm-0 d-flex flex-sm-column align-items-center">
                                <img alt="BabyBeat Fetal Monitor in India, CareNX, CareNX providing NST/CTG tests at home" class="mb-3 mr-sm-0 mr-4" src="../../../../../assets/images/products/babybeat/stats/care.svg">
                                <div>
                                    <h2 class="color-primary mb-2 text-sm-center">Care</h2>
                                    <p class="color-primary font-13 text-sm-center">Get hospital level NST<br class="d-none d-sm-block">
                                        (non-stress test) at home</p>
                                </div>
                            </div>
                            <div class="stats d-flex flex-sm-column align-items-center">
                                <img alt="BabyBeat Fetal Monitor in India, CareNX, CareNX providing NST/CTG tests at home" class="mb-3 mr-sm-0 mr-4" src="../../../../../assets/images/products/babybeat/stats/share.svg">
                                <div>
                                    <h2 class="color-primary mb-2 text-sm-center">Share</h2>
                                    <p class="color-primary font-13 text-sm-center">Share reports with doctor,<br class="d-none d-sm-block">
                                        friends and family</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="image-container mb-4 mb-lg-0 d-flex align-items-center justify-content-center">
                        <img alt="We’re CareNX, A Digital Maternity Solution Platform For Healthcare Providers" src="../../../../../assets/images/products/babybeat/stats/stats-bg.png">
                    </div>

                    <div class="mb-4 d-block d-lg-none">
                        <h1 class="color-bb-secondary text-left px-3 px-sm-5">Experience the joy of your baby’s every<br class="d-none d-xl-block">
                            beat in 3 easy steps</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>