<section id="intro-wrap" class="intro-ten-Wrap text-white">
    <div class="container  inner-section">
        <div class="row align-items-center">
            <div class="col-md-6 col-lg-7 align-items-center">
                <div>
                    <span class="Take-baby-steps-towa">Take baby steps towards a healthy pregnancy</span>
                    <p class="With-proper-guidance mb-4">With proper guidance, every pregnancy can be a healthy & happy
                        experience </p>
                    <div class="mb-1 lead">
                        <ul class="list-inline">
                            <li class="mb-3"><i class="eva eva-checkmark-outline pr-1 chk-icon"></i>
                                <span class="checkMark-text">Get a 40 week calendar of do’s, don’ts, tips and facts.</span>
                            </li>
                            <li class="mb-3"><i class=" eva eva-checkmark-outline pr-1 chk-icon"></i>
                                <span class="checkMark-text">Curated videos to guide you through your pregnancy.</span>
                            </li>
                            <li class="mb-3"><i class="eva eva-checkmark-outline pr-1 chk-icon"></i>
                                <span class="checkMark-text">Get professional counselling on demand from experts.</span>
                            </li>
                        </ul>
                    </div>
                    <div class="intro-ten-Buttons d-sm-block">
                        <a class="btn btn-lg p-0 mr-3 mb-3" 
                            href="https://play.google.com/store/apps/details?id=app.carenx.caremother.android" role="button"  target="_blank">
                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="./assets/img/android.jpg" class="download-button">
                        </a>
                        <a class="btn btn-lg p-0 mb-3" href="https://apps.apple.com/in/app/caremother-pregnancy-tracker/id1506728456"
                         role="button" >
                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="./assets/img/apple-store.jpg" class="download-button">
                        </a>
                    </div>
                </div>
                
            </div>


            <div class="col-md-6 col-lg-5 intro-ten-RightSection  align-items-center d-sm-block">
                <div class="intro-ten-ProductImage">
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="./assets/img/mother-land.jpg" class="img-responsive intro-ten-img" alt="image here "
                        >
                </div>
            </div>
        </div>
    </div>
</section>