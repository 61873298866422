import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LifeCaremotherComponent } from './life-caremother.component';

const routes: Routes = [
  {
    path: '',
    component: LifeCaremotherComponent,
    data: {
      title: 'Life at CareNX',
      keywords: 'Life at CareNX, CareNX',
      description: 'Life at CareNX'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LifeCaremotherRoutingModule { }
