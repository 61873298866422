<section id="journey-section">
    <div class="container inner-section">
        <div class="row">
            <div class="col-12 col-md-6 inner-visual" >
                <div class="h-100 d-flex align-items-center">
                    <img alt="CareNX Vision and Mission" class=" mb-5 mb-md-0" src="../../../../../assets/images/company/about/journey/journey_image.jpeg">
                </div>
            </div>
            <div class="col-12 col-md-6 inner-details">
                <div class="details-container">
                    <div class="mb-5" >
                        <div class="details-title mb-2">
                            <img alt="CareNX Creating equal opportunities of care for women" src="../../../../assets/images/company/about/journey/mission.jpeg">
                            <h1 class="mt-2 text-center text-md-left color-secondary">Mission</h1>
                        </div>
                        <p class="font-15 text-center text-md-left">
                            Creating equal opportunities of care for women
                        </p>
                    </div>
                    <div >
                        <div class="details-title mb-2">
                            <img alt="CareNX To be India's best MedTech and Digital-Health provider for pregnancy care by 2025." 
                            src="../../../../assets/images/company/about/journey/vision.jpeg">
                            <h1 class="mt-2 text-center text-md-left color-secondary">Vision</h1>
                        </div>
                        <p class="font-15 text-center text-md-left">
                            To be India's best MedTech and Digital-Health provider <br class="d-none d-lg-block">
                            for women healthcare by 2025.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>