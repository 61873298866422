import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home.routing';

import { ComponentsModule } from '../shared/components/components.module';
import { MaterialModule } from '../shared/modules/material/material.module';

//Home Components
import { RollingCounterComponent } from './components/rolling-counter/rolling-counter.component';
import { HomeDetailsComponent } from './components/home-details/home-details.component';
import { OfferingComponent } from './components/offering/offering.component';
import { StandardsComponent } from './components/standards/standards.component';
import { HomePartnersComponent } from './components/home-partners/home-partners.component';
import { InfographicsComponent } from './components/infographics/infographics.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { HomeVideosComponent } from './components/home-videos/home-videos.component';
import { HomeAwardsComponent } from './components/home-awards/home-awards.component';
import { HomeElementComponent } from './components/home-element/home-element.component';
import { HomeInfoComponent } from './components/home-info/home-info.component';

@NgModule({
  declarations: [
    HomeComponent,
    RollingCounterComponent,
    HomeDetailsComponent,
    OfferingComponent,
    StandardsComponent,
    InfographicsComponent,
    HomePartnersComponent,
    ImageSliderComponent,
    HomeVideosComponent,
    HomeAwardsComponent,
    HomeElementComponent,
    HomeInfoComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    ComponentsModule,
    MaterialModule
  ]
})
export class HomeModule { }
