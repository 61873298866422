<section class="bg-color-secondary">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="col-12">
                <div class="inner-block d-flex align-items-center justify-content-center flex-column flex-lg-row justify-content-lg-between">
                    <div class="mb-4 mb-md-0 details-container" >
                        <p class="text-white mb-3">Join Our Team!</p>
                        <h1 class="text-white mb-4">
                            If you’re looking to grow your career with a <br>
                            team of passionate and aspiring leaders.
                        </h1>
                        <div>
                            <button (click)="scrollTo('openings')" color="white" button-filled>See Open Roles</button>            
                        </div>
                    </div>
                    <img alt="Join CareNX" src="../../../../../assets/images/company/careers/intro/intro-img.png">
                </div>
            </div>
        </div>
    </div>
</section>