<section>
    <div class="left-bg bg-color-primary-light d-none d-lg-block">
        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../../assets/images/products/fetosense/components/feto-05.png">
    </div>
    <div class="container inner-section">
        <div class="row align-items-center">
            <div class="col-12">
                <div class="inner-block d-flex flex-lg-row flex-column justify-content-between">
                    <div class="image-container mb-5 mb-lg-0 d-flex align-items-center justify-content-center">
                        <img alt="We’re CareNX, A Digital Maternity Solution Platform For Healthcare Providers" src="../../../../../assets/images/products/fetosense/components/feto-04.png">
                    </div>
                    
                    <div class="details-container d-flex flex-column align-items-center align-items-lg-start justify-content-center mb-4 mb-md-0" >
                        <h1 class="color-secondary mb-5 text-lg-left text-center">
                            Fetosense Kit<br>
                            Main Components</h1>
                        <ul>
                            <li class=" mb-4">
                                <p class="font-18 color-primary ml-4">Speaker</p>
                            </li>
                            <li class=" mb-4">
                                <p class="font-18 color-primary ml-4">Tablet</p>
                            </li>
                            <li class=" mb-4">
                                <p class="font-18 color-primary ml-4">Toco Probe</p>
                            </li>
                            <li class=" mb-4">
                                <p class="font-18 color-primary ml-4">Doppler or FHR Probe</p>
                            </li>
                            <li class=" mb-4">
                                <p class="font-18 color-primary ml-4">Movement Marker</p>
                            </li>
                            <li class=" mb-4">
                                <p class="font-18 color-primary ml-4">Aluminum Case</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>