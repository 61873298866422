import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-career-intro',
  templateUrl: './career-intro.component.html',
  styleUrls: ['./career-intro.component.scss']
})
export class CareerIntroComponent implements OnInit {
  isFixed: boolean;

  constructor() { }

  ngOnInit() {
  }
  
  scrollTo(sectionName){
    let element = document.getElementById(sectionName);
    element.scrollIntoView({behavior:'smooth'});
  }
  
}
