<section id="openings" class="bg-color-secondary-lighter">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="col-12">
                <div class="inner-block">
                    <h1 class="mb-3 text-center color-secondary" >Latest Open Positions</h1>
                    <p class="mb-4 text-center color-primary font-16" >
                        If you’re a passionate, hard-working person wanting to make a big impact in the work place,
                        <br>
                        then we’d be delighted to work with you!
                    </p>
                </div>
                <div *ngIf="noOpening" >
                    <p class="color-primary text-center">Sorry, there are no positions available at this time.</p>
                </div>
                <div class="card-block">
                    <cdk-accordion class="example-accordion">
                        <cdk-accordion-item *ngFor="let item of openingsList"
                        #accordionItem="cdkAccordionItem"
                        class="example-accordion-item"
                        role="button"
                        tabindex="0"
                        [attr.expanded]="accordionItem.expanded"
                        [attr.id]="'accordion-header-' + index"
                        [attr.aria-expanded]="accordionItem.expanded"
                        [attr.aria-controls]="'accordion-body-' + index">
                            <div id="{{item.id}}" style="padding-top: 30px;">
                                <div class="card" >
                                    <div class="card-body">
                                        <div class="example-accordion-item-header accordian-inner">
                                            <h2 class="color-primary mb-2">{{item.title}}</h2>
                                            <div class="d-flex flex-column flex-md-row justify-content-md-between mb-3">
                                                <p class="color-light font-15">Location: {{item.location}}</p>
                                                <p class="color-light font-15" *ngIf="accordionItem.expanded">Department: {{item.department}}</p>
                                                <p class="color-light font-15" *ngIf="accordionItem.expanded">Type: {{item.type}}</p>
                                                <p class="color-light font-15" *ngIf="accordionItem.expanded">Experience: {{item.experience}}</p>
                                            </div>
                                        </div>
                                        <div class="example-accordion-item-body mb-5"
                                            role="region"
                                            [style.display]="accordionItem.expanded ? '' : 'none'"
                                            [attr.id]="'accordion-body-' + index"
                                            [attr.aria-labelledby]="'accordion-header-' + index">
                                            <div class="accordian-inner">
                                                <div class="mt-5">
                                                    <p class="color-primary font-15 mb-4">
                                                        Our mission at CareNX is to help pregnant women and hospitals provide personalised and premium 
                                                        care with our advanced maternity solutions.
                                                    </p>
                                                    <p class="color-primary font-15 mb-4">
                                                        Backed by notable investors, funders and partners, CareNX is a successful startup in Navi Mumbai. 
                                                        we’re a close-knit team driven by honesty, hard work, fun and creativity. At CareNX every team 
                                                        member has a big impact on our business, and on the world!
                                                    </p>
                                                    <p class="color-primary font-15 mb-4">
                                                        You can learn more about our company here: <a href="https://carenx.com" class="color-secondary">www.carenx.com</a>
                                                    </p>
                                                
                                                    <p class="color-secondary font-17 mb-3">What will I be doing?</p>
                                                    <ul class="dashed mb-4">
                                                        <li class="color-primary font-15 mb-2" *ngFor="let item of item.role"> {{item}}</li>
                                                    </ul>
                                                
                                                    <p class="color-secondary font-17 mb-3">Necessary Skills</p>
                                                    <ul class="dashed mb-4">
                                                        <li class="color-primary font-15 mb-2" *ngFor="let item of item.skills"> {{item}}</li>
                                                    </ul>
                                                
                                                    <p class="color-secondary font-17 mb-3">Education</p>
                                                    <ul class="dashed mb-4">
                                                        <li class="color-primary font-15 mb-2" *ngFor="let item of item.education"> {{item}}</li>
                                                    </ul>
                                                
                                                    <p class="color-secondary font-17 mb-3">Benefits and Perks</p>
                                                    <ul class="dashed mb-4">
                                                        <li class="color-primary font-15 mb-2">Insurance benefits</li>
                                                        <li class="color-primary font-15 mb-2">Flexible paid time off</li>
                                                        <li class="color-primary font-15 mb-2">Paid parental leave</li>
                                                        <li class="color-primary font-15 mb-2">Casual dress code</li>
                                                        <li class="color-primary font-15 mb-2">Free snacks</li>
                                                    </ul>
                                                
                                                    <p class="color-primary font-15 mb-4">
                                                        A normal work day at CareNX hardly feels like work at all. We love to push the limits utilizing the 
                                                        latest modern tech and take pride in delivering to our users a platform that is delightful, intuitive and 
                                                        beautiful.
                                                    </p>
                                                    <p class="color-primary font-15 mb-5">
                                                        Personal learning and growth is also a key ingredient to CareNX’s culture. Managers act as mentors 
                                                        helping guide each team member in achieving their goals. If you’re a passionate, hard-working person 
                                                        wanting to make a big impact in their workplace, then we’d be delighted to work with you!
                                                    </p>
                                                    <div class="btn-group mb-2">
                                                        <div class="mr-3">
                                                            <a [routerLink]="[item.documentId]" color="secondary" button-filled>I’m Interested</a>
                                                        </div>
                                                        <div>
                                                            <button (click)="share(item.title, item.location, item.experience)" color="secondary" button-outlined>Share Job</button> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="font-17 color-secondary" (click)="accordionItem.toggle();">
                                            <p *ngIf="!accordionItem.expanded" (click)="scrollToOpenings(item.id)">+ View Details</p>
                                            <p *ngIf="accordionItem.expanded" (click)="scrollToOpenings('openings')">- Hide Details</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </cdk-accordion-item>
                    </cdk-accordion>
                </div>
            </div>   
        </div>
    </div>
</section>