import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FetosenseComponent } from './fetosense.component';
import { FetosenseRoutingModule } from './fetosense.routing';
import { NgSelectModule } from '@ng-select/ng-select';

import { ComponentsModule } from 'src/app/shared/components/components.module';
import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { FetosenseIntroComponent } from './components/fetosense-intro/fetosense-intro.component';
import { FetosenseFeaturesComponent } from './components/fetosense-features/fetosense-features.component';
import { FetosenseComponentsComponent } from './components/fetosense-components/fetosense-components.component';
import { FetosenseStatsComponent } from './components/fetosense-stats/fetosense-stats.component';
import { FetosenseTestimonialsComponent } from './components/fetosense-testimonials/fetosense-testimonials.component';
import { FetosenseVideosComponent } from './components/fetosense-videos/fetosense-videos.component';
import { FetosenseEnquiryComponent } from './components/fetosense-enquiry/fetosense-enquiry.component';
import { FetosenseLocationsComponent } from './components/fetosense-locations/fetosense-locations.component';

@NgModule({
  declarations: [FetosenseComponent, FetosenseIntroComponent, FetosenseFeaturesComponent, FetosenseComponentsComponent, FetosenseStatsComponent, FetosenseTestimonialsComponent, FetosenseVideosComponent, FetosenseEnquiryComponent, FetosenseLocationsComponent],
  imports: [
    CommonModule,
    FetosenseRoutingModule,
    ComponentsModule,
    MaterialModule,
    NgSelectModule
  ]
})
export class FetosenseModule { }
