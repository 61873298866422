import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anandimaa-enquiry',
  templateUrl: './anandimaa-enquiry.component.html',
  styleUrls: ['./anandimaa-enquiry.component.scss']
})
export class AnandimaaEnquiryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
