import { Component, OnInit } from '@angular/core';
import Glide from '@glidejs/glide'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

@Component({
  selector: 'app-sindicolpo-testimonials',
  templateUrl: './sindicolpo-testimonials.component.html',
  styleUrls: ['./sindicolpo-testimonials.component.scss']
})
export class SindiColpoTestimonialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    new Glide('.glide__sindicolpo_testimonials',{
      type: 'carousel',
      startAt: 0,
      perView: 1,
      gap: 30,
      focusAt: 'center'
    }).mount({ Controls, Breakpoints })
  }

}
