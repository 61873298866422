<section class="bg-color-secondary-lighter">
    <div class="container inner-section">
        <div class="row">
            <div class="col-12">
                <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <div class="d-flex flex-column align-items-center d-md-block" >
                        <h2 class="color-primary font-28 mb-3 text-center text-md-left">
                            Experience free demo of CareNX offerings,<br class="d-none d-xl-block">
                            trusted by more than 3000+ healthcare providers.
                        </h2>
                        <div>
                            <a routerLink="/offering" class="btn btn-lg primary-btn mr-2" role="button" >
                                Explore Offerings</a>
                        </div>
                    </div>
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" 
                    src="../../../../assets/images/home/human-element/doctor image.png">
                </div>
            </div>
        </div>
    </div>
</section>