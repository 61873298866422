export const environment = {
  production: false,
  firebaseConf: {
    apiKey: "AIzaSyDdq5j17V01upq9XKirbF1NKe5jItUsOew",
    authDomain: "caremother-v2.firebaseapp.com",
    databaseURL: "https://caremother-v2.firebaseio.com",
    projectId: "caremother-v2",
    storageBucket: "caremother-v2.appspot.com",
    messagingSenderId: "478565085176",
    appId: "1:478565085176:web:7596de91673b3d6701b00d"
  }
}; 
 