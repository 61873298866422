import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-nav',
  templateUrl: './page-nav.component.html',
  styleUrls: ['./page-nav.component.scss']
})
export class PageNavComponent implements OnInit {

  isVisible: boolean;
  isFixed: boolean;

  navItems: any[];
  sections: any;
  navLi: any;

  constructor(private router: Router) {


    if(this.router.url === '/offering/babybeat'){
      
      this.navItems = []
        
    }else if(this.router.url.includes('/offering/fetosense')){
        
      this.navItems = [
        {
          navName: 'Components',
          navScroll: 'components-section'
        },
        {
          navName: 'Features',
          navScroll: 'features-section'
        },
        {
          navName: 'How It Works',
          navScroll: 'how-it-works-section'
        },
        {
          navName: 'Partners',
          navScroll: 'partners-section'
        },
        {
          navName: 'Testimonial',
          navScroll: 'testimonials-section'
        },
        {
          navName: 'News',
          navScroll: 'featured-section'
        },
        {
          navName: 'Locate Us',
          navScroll: 'locations-section'
        },
        {
          navName: 'Enquiry',
          navScroll: 'enquiry-section'
        },
      ]
        
    }else if(this.router.url.includes('/offering/anandimaa')){
        
      this.navItems = [
        {
          navName: 'Offerings',
          navScroll: 'offering-section'
        },
        {
          navName: 'Benefits',
          navScroll: 'benefits-section'
        },
        {
          navName: 'Usecases',
          navScroll: 'usecases-section'
        },
        {
          navName: 'How It Works',
          navScroll: 'how-it-works-section'
        },
        {
          navName: 'Partners',
          navScroll: 'partners-section'
        },
        {
          navName: 'Testimonial',
          navScroll: 'testimonials-section'
        },
        {
          navName: 'Locate Us',
          navScroll: 'presense-section'
        },
        {
          navName: 'Enquiry',
          navScroll: 'enquiry-section'
        },
      ]
        
    }else if(this.router.url.includes('/company/about-us')){
        
      this.navItems = [
        {
          navName: 'Our Journey',
          navScroll: 'journey-section'
        },
        {
          navName: 'Our Story',
          navScroll: 'story-section'
        },
        {
          navName: 'Awards & Recognitions',
          navScroll: 'awards-section'
        },
        {
          navName: 'Supporters & Partners',
          navScroll: 'partners-section'
        },
        {
          navName: 'Publications',
          navScroll: 'publications-section'
        },
        {
          navName: 'Newsroom',
          navScroll: 'news-section'
        }
      ]
        
    }else if(this.router.url.includes('/company/life-at-caremother')){
        
      this.navItems = [
        {
          navName: 'Our Values',
          navScroll: 'values-section'
        },
        {
          navName: 'Meet The Team',
          navScroll: 'team-section'
        },
        {
          navName: 'Inspiring Stories',
          navScroll: 'testimonials-section'
        },
        {
          navName: 'Fun Moments',
          navScroll: 'workplace-section'
        }
      ]
        
    }else if(this.router.url.includes('/offering')){
        
      this.navItems = [
        {
          navName: 'BabyBeat',
          navScroll: 'babybeat-section'
        },
        {
          navName: 'Fetosense',
          navScroll: 'fetosense-section'
        },
        {
          navName: 'AnandiMaa',
          navScroll: 'anandimaa-section'
        },
      ]
        
    }else if(this.router.url.includes('/company/careers')){
        
      this.navItems = [
        {
          navName: 'Our Values',
          navScroll: 'values-section'
        },
        {
          navName: 'Job Openings',
          navScroll: 'openings'
        },
        {
          navName: 'Why Join Us',
          navScroll: 'why-join-us-section'
        },
      ]
        
    }
  }

  ngOnInit() {
  }

  scrollTo(sectionName){
    let element = document.getElementById(sectionName);
    element.scrollIntoView({behavior:'smooth'});
  }
  
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if(typeof window !== undefined ){
          
      const offset =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (offset > 650) {
        this.isFixed = true;
        if(offset>650){
          this.isVisible = true;
        }else{
          this.isVisible = false;
        }
      } else if(offset <= 650){
        this.isFixed = false
        this.isVisible = false;
      }
      else {
        this.isFixed = false;
        this.isVisible = false;
      }

      let sections = document.querySelectorAll('section');
      let navLi = document.querySelectorAll("a");

      let current = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (scrollY >= sectionTop - sectionHeight / 3) {
          current = section.getAttribute("id");
        }
      });

      navLi.forEach((a) => {
        a.classList.remove("active");
        if (a.classList.contains(current)) {
          a.classList.add("active");
        }
      });
    }
  }

}
