import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  user: any;

  constructor(private afs: AngularFirestore) { }

  checkUser(email){
    return this.afs.collection('Subscription', ref => ref.where('email', '==', email).where('delete', '==', false)).valueChanges();
  }

  subscribeUser(email){
    this.user = {
      documentId: this.afs.firestore.collection("Subscription").doc().id,
      email: email,
      delete: false
    };
    return this.afs.collection('Subscription').doc(this.user.documentId).set(this.user).then((docRef) => {
          
      return this.afs.collection('Subscription').doc(this.user.documentId).get().toPromise();
      
    }).catch(function (error) {
        console.log("Error:", error);
    });
  }
}
