import { Component, OnInit } from '@angular/core';
import Glide from '@glidejs/glide'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

@Component({
  selector: 'app-people-testimonials',
  templateUrl: './people-testimonials.component.html',
  styleUrls: ['./people-testimonials.component.scss']
})
export class PeopleTestimonialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    new Glide('.glide__people',{
      type: 'carousel',
      startAt: 0,
      perView: 1,
      gap: 30,
      focusAt: 'center'
    }).mount({ Controls, Breakpoints })
  }

}
