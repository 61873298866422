<div class="container inner-section">
    <div class="row pt-4">
        <div class="col-12">
            <h4>TERMS OF USE AND PRIVACY POLICY</h4>
        </div>
        <div class="col-12 text-justify">
            <p>This application “CareNX - Pregnancy Tracker & Baby Growth” for mobile devices is created by CareNX Innovations Pvt. Ltd. (Developer) and is intended for Expectant mothers/Pregnant women (USER) with an aim to enable fetal heart assessment with evidence-based information and support to make informed decisions related to the fetus health during pregnancy. The application also aims to keep the doctor and User in constant contact. The information on her progress will be provided by the Physicians/Gynecologists/Medical Professionals/ANMs/Skilled front-line health workers/ Hospital (hereinafter referred to as the Doctor) that the User is registered with.
        </div>
    </div>
    <div class="row pt-4">
        <div class="col-12">
            <h4>Information provided by the User and the Doctor:</h4>
        </div>
        <div class="col-12">
            <ol>
                <li class="pt-2 text-justify">
                    The Application obtains the information when the User downloads and registers the Application on the smart phone. Registration is mandatory in order to be able to use the basic features of the application. 
                </li>
                <li class="pt-2 text-justify">
                    During registration, the User should provide (a) their name, email address, age, user name, password, mobile number,last menstrual date and any other registration information relevant to the use of the application; the name ID of the doctor assigned by CareNX platform and hospital ID/ clinic ID that the doctor is attached to. Which can be obtained from the associated doctors/support system.
                </li>
                <li class="pt-2 text-justify">
                    Upon completion of the registration, the application requires the doctor get access to patient test data
                </li>
                <li class="pt-2 text-justify">
                    All the medical related data is provided by the Doctor in compliance with the rules of the hospital and best of their knowledge.
                </li>
            </ol>
        </div>

    </div>

    <div class="row pt-4">
        <div class="col-12">
            <h4>Automatically Collected Information</h4>
        </div>
        <div class="col-12 text-justify">
            In addition, the Application may collect certain information automatically, including, but not limited to, the type of mobile device the User uses, the mobile devices unique device ID, the IP address of the mobile device, the mobile operating system, the type of mobile Internet browsers being used, and information about the way the application is being used.
        </div>
    </div>


    <div class="row pt-3">
        <div class="col-12">
            <h4>Location information</h4>
        </div>
        <div class="col-12 text-justify">
            This Application does not collect precise information about the location of the Users mobile device.
        </div>
    </div>


    <div class="row pt-4">
        <div class="col-12">
            <h4>Access by third parties of the data and information obtained by the Application</h4>
        </div>
        <div class="col-12 text-justify">
            Only aggregated, anonymize data is periodically transmitted to external services to help the Developer improve the Application and their service. The Developer will share the Users information with third parties only in the ways that are described in this privacy statement.
        </div>
        <div class="col-12  text-justify">
            The Developer may disclose User Provided and Automatically Collected Information, in the following circumstances:
        </div>
        <div class="col-12">
            <ul>
                <li class="text-justify pt-2">
                    as required by law, such as to comply with any legal process;
                </li>
                <li class="text-justify pt-2">
                    when the Developers believe in good faith that disclosure is necessary to protect their rights, protect the Users safety or the safety of others, including Doctors and Hospitals, investigate fraud, or respond to a government request;
                </li>
                <li class="text-justify pt-2"> 
                    with the Developers trusted services providers who work on their behalf, do not have an independent use of the information disclosed to them, and have agreed to adhere to the rules set forth in this privacy statement. 
                </li>
            </ul>
        </div>
    </div>




    <div class="row pt-4">
        <div class="col-12">
            <h4>Opt-out rights?</h4>
        </div>
        <div class="col-12 text-justify">
            The User can stop all collection of information by the Application easily by uninstalling the Application. The User may use the standard uninstall processes as may be available as part of their mobile device or via the mobile application marketplace or network. The User can also request to opt-out via email, at info@carenx.com (the Developers email)
        </div>
    </div>


    <div class="row pt-4">
        <div class="col-12">
            <h4>Data Retention Policy, Managing Your Information</h4>
        </div>
        <div class="col-12 text-justify">
            The Developer will retain User Provided data for as long as the User uses the Application and for a reasonable time thereafter. The Developer will retain Automatically Collected information for up to 24 months and thereafter may store it in aggregate. If the User wants the Developer to delete User Provided Data that has been provided via the Application, then the User may contact the Developer at info@carenx.com and the Developer will respond in a reasonable time. Please note that some or all of the User Provided Data may be required in order for the Application to function properly.
        </div>
    </div>


    <div class="row pt-4">
        <div class="col-12">
            <h4> Security</h4>
        </div>
        <div class="col-12 text-justify">
            The Developer is concerned about safeguarding the confidentiality of the Users information. The Developer provide physical, electronic, and procedural safeguards to protect information they process and maintain. For example, the Developer limits access to this information only to authorized employees and healthcare providers who need to know that information in order to operate, develop or improve their Application/Services. Please be aware that, although the Developer endeavours to provide reasonable security for information that they process and maintain, no security system can prevent all potential security breaches. SSL and Encryption techniques are used.
        </div>
    </div>


    <div class="row pt-4">
        <div class="col-12">
            <h4>Changes</h4>
        </div>
        <div class="col-12 text-justify">
            This Privacy Policy may be updated from time to time for any reason. The Developer will notify the User of any changes to their Privacy Policy by posting the new Privacy Policy here and informing the User via email or text message or in app notification. The User is advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.
        </div>
    </div>


    <div class="row pt-4 pb-3">
        <div class="col-12">
            <h4>Your Consent</h4>
        </div>
        <div class="col-12 text-justify">
            By using the Application, the User is consenting to the Developers processing of their information as set forth in this Privacy Policy now and as amended by them. "Processing,” means using cookies on a computer/hand held device or using or touching information in any way, including, but not limited to, collecting, storing, deleting, using, combining and disclosing information, all of which activities will take place in India. If the User resides outside the India, then the information will be transferred, processed and stored there under the Indian privacy standards.
        </div>
    </div>


</div>