import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CareerComponent } from './career.component';
import { OpeningComponent } from './opening/opening.component';


const routes: Routes = [
  {
    path: '',
    component: CareerComponent,
    data: {
      title: 'Careers | Jobs Opening in CareNX',
      keywords: 'Careers, Jobs Opening in CareNX',
      description: 'Share your updated resume at hr@carenx.com'
    }
  },
  {
    path: ':id',
    component: OpeningComponent,
    data: {
      title: 'Careers | Jobs Opening in CareNX',
      keywords: 'Careers, Jobs Opening in CareNX',
      description: 'Share your updated resume at hr@carenx.com'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CareerRoutingModule { }
