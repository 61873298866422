import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SindiColpoComponent } from './sindicolpo.component';
import { SindiColpoRoutingModule } from './sindicolpo.routing';
import { NgSelectModule } from '@ng-select/ng-select';

import { ComponentsModule } from 'src/app/shared/components/components.module';
import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { SindiColpoIntroComponent } from './components/sindicolpo-intro/sindicolpo-intro.component';
import { SindiColpoFeaturesComponent } from './components/sindicolpo-features/sindicolpo-features.component';
import { SindiColpoComponentsComponent } from './components/sindicolpo-components/sindicolpo-components.component';
import { SindiColpoStatsComponent } from './components/sindicolpo-stats/sindicolpo-stats.component';
import { SindiColpoTestimonialsComponent } from './components/sindicolpo-testimonials/sindicolpo-testimonials.component';
import { SindiColpoVideosComponent } from './components/sindicolpo-videos/sindicolpo-videos.component';
import { SindiColpoEnquiryComponent } from './components/sindicolpo-enquiry/sindicolpo-enquiry.component';
import { SindiColpoLocationsComponent } from './components/sindicolpo-locations/sindicolpo-locations.component';

@NgModule({
  declarations: [SindiColpoComponent, SindiColpoIntroComponent, SindiColpoFeaturesComponent, SindiColpoComponentsComponent, SindiColpoStatsComponent, SindiColpoTestimonialsComponent, SindiColpoVideosComponent, SindiColpoEnquiryComponent, SindiColpoLocationsComponent],
  imports: [
    CommonModule,
    SindiColpoRoutingModule,
    ComponentsModule,
    MaterialModule,
    NgSelectModule
  ]
})
export class SindiColpoModule { }
