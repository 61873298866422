<section id="details-section">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="col-6 inner-visual">
                <div class="inner-bg">
                    <img alt="CareNX - Enabling Hospitals Provide Women Healthcare 
                    Care Beyond Facility" src="../../../../assets/images/home/home-details.jpg">
                </div>
            </div>
            <div class="col-6 inner-details">
                <div class="details-container">
                    <h1 class="mb-3">CareNX</h1>
                    <p class="mb-3">Enabling Hospitals Provide Women 
                        Healthcare Beyond Facility</p>
                    <p class="">
                        We are an IIT Bombay based healthcare startup which develops and scales AI based women healthcare solutions. 
                    </p>
                    <div class="intro-buttons mt-3">
                        <a routerLink="/company/about-us" class="btn btn-lg primary-btn mr-2" role="button" >
                            About Us</a>
                        <a routerLink="/contact" class="btn btn-lg secondary-btn" role="button" >
                            Get In Touch</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>