import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fetosense-features',
  templateUrl: './fetosense-features.component.html',
  styleUrls: ['./fetosense-features.component.scss']
})
export class FetosenseFeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
