import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from  '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  errorMsg: any;
  isError: any;
  contactForm: UntypedFormGroup;

  private formValues = {
    'email': null,
    'name': null,
    'phone': null,
    'message': null,
    'subject': null,
    'apiKey': null,
  };

  constructor(private router:Router, private fb: UntypedFormBuilder, private http: HttpClient) {
    let phoneNumber = "^[0-9]*$";
    let emailPattern = "^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['',[ 
        Validators.required,
        Validators.pattern(emailPattern),
      ]],
      mobile: ['',[
        Validators.required, 
        Validators.pattern(phoneNumber)
      ]],
      profession: ['', Validators.required],
      //product: ['', Validators.required],
      message: [''],
    });
  }

  ngOnInit() { }

  submitForm(){
    if(this.contactForm.invalid){
      
    }else{
      this.formValues.email = this.contactForm.value.email;
      this.formValues.name = this.contactForm.value.name;
      this.formValues.phone = this.contactForm.value.mobile;

      if(this.contactForm.value.message === "" || undefined || null){
        this.formValues.message = 'Hello, I would like to know about CareNX';
      }
      else{
        this.formValues.message = this.contactForm.value.message;
      }

      this.formValues.subject = 'CareNX'
      this.formValues.apiKey = 'pB9DJrDNaAOWiPoS9bBT';

      console.log('FORM VALUES :',this.formValues)

      this.http.post('https://caremother.co:3000/api/sendInquiries', this.formValues).subscribe((data) => {
          this.contactForm.reset({profession: ''});

          Swal.fire({
            html:'<h1 class="swal-title">Your Message Is Sent Successfully</h1>',
            //text: "You have successfully subscribed to our list. You'll be receiving a confirmation email soon. Now, you can stay up-to-date with all the CareNX news.",
            icon: 'success',
            width: '50rem',
            iconColor: '#00A69A',
            confirmButtonColor: '#00A69A',
            confirmButtonText: 'Go Back',
            focusConfirm: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false
          })


          this.formValues.email = null;
          this.formValues.name = null;
          this.formValues.phone = null;
          this.formValues.subject = null;
          this.formValues.message = null;
        },
        (error: HttpErrorResponse) => {
          console.log(error, 'data');
        }
      )
    }
  }

}
