import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompanyRoutingModule } from './company.routing';
import { AboutUsModule } from './about-us/about-us.module';
import { LifeCaremotherModule } from './life-caremother/life-caremother.module';
import { CareerModule } from './career/career.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CompanyRoutingModule,
    AboutUsModule,
    LifeCaremotherModule,
    CareerModule
  ]
})
export class CompanyModule { }
