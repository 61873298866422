import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  isProductsPage:Boolean = false;

  constructor(private router:Router) {
    if(this.router.url == '/products'){
      this.isProductsPage = true;
    }
  }

  ngOnInit() {
  }

}
