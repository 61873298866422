import { Component, OnInit } from '@angular/core';
import Glide from '@glidejs/glide'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

@Component({
  selector: 'app-featured-in',
  templateUrl: './featured-in.component.html',
  styleUrls: ['./featured-in.component.scss']
})
export class FeaturedInComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    new Glide('.glider__featured__in',{
      type: 'carousel',
      startAt: 0,
      perView: 6,
      bound: true,
      autoplay: 2000,
      hoverpause: false,
      animationTimingFunc: "ease",
      animationDuration: 800,
      breakpoints: {
        1200: {
          perView: 5
        },
        992: {
          perView: 4
        },
        768: {
          perView: 3
        },
        576: {
          perView: 2
        },
      }
    }).mount({ Controls, Breakpoints })
  }

}
