<div [class.isFixed]="isFixed">
    <app-main-navbar></app-main-navbar>
    <app-babybeat-intro></app-babybeat-intro>
    <app-fixed-socials></app-fixed-socials>
    <app-babybeat-features></app-babybeat-features>
    <app-babybeat-components></app-babybeat-components>
    <app-babybeat-stats></app-babybeat-stats>
    <app-babybeat-testimonials></app-babybeat-testimonials>
    <app-babybeat-videos></app-babybeat-videos>
    <app-babybeat-enquiry></app-babybeat-enquiry>
    <app-babybeat-faq></app-babybeat-faq>
    <app-babybeat-locations></app-babybeat-locations>
    <app-footer></app-footer>
</div>