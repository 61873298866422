import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anandimaa-presense',
  templateUrl: './anandimaa-presense.component.html',
  styleUrls: ['./anandimaa-presense.component.scss']
})
export class AnandimaaPresenseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
