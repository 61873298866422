import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactComponent } from './contact.component';
import { ContactRoutingModule } from './contact.routing';

import { ComponentsModule } from '../shared/components/components.module';
import { ContactFormComponent } from './components/contact-form/contact-form.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { LocationComponent } from './components/location/location.component';

import { MaterialModule } from '../shared/modules/material/material.module';

@NgModule({
  declarations: [ContactComponent, ContactFormComponent, SubscribeComponent, LocationComponent],
  imports: [
    CommonModule,
    ContactRoutingModule,
    ComponentsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ContactModule { }
