import { Component, HostListener, OnInit } from '@angular/core';

import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-sindicolpo',
  templateUrl: './sindicolpo.component.html',
  styleUrls: ['./sindicolpo.component.scss']
})
export class SindiColpoComponent implements OnInit {

  constructor(
    private title: Title,
    private meta: Meta) {
      
    this.title.setTitle('SindiColpo | AI based cervical cancer screening solution');
    this.meta.updateTag({ name: 'description', content: "SindiColpo is a smartphone based colposcopy machine. Wireless | Portable | Remote Monitoring." })
    this.meta.updateTag({ name: 'keywords', content: "Colposcopy, Cervical cancer, AI, CareNX" })
    }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  isFixed: boolean;
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if(typeof window !== undefined ){
          
      const offset =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (offset > 400) {
        this.isFixed = true;
      } else if(offset <= 500){
        this.isFixed = false
      }
      else {
        this.isFixed = false;
      }
    }
  }
}
