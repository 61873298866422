import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { CareerComponent } from './career.component';
import { CareerRoutingModule } from './career.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from '../../shared/components/components.module';
import { CareerIntroComponent } from './components/career-intro/career-intro.component';
import { CareerWhyJoinUsComponent } from './components/career-why-join-us/career-why-join-us.component';
import { CareerOpeningsComponent } from './components/career-openings/career-openings.component';

import { MaterialModule } from 'src/app/shared/modules/material/material.module';
import { CareerBenefitsComponent } from './components/career-benefits/career-benefits.component';
import { OpeningComponent } from './opening/opening.component';
import { OpeningsCardComponent } from './components/openings-card/openings-card.component';
@NgModule({
  declarations: [
    CareerComponent,
    CareerIntroComponent,
    CareerWhyJoinUsComponent,
    CareerOpeningsComponent,
    CareerBenefitsComponent,
    OpeningComponent,
    OpeningsCardComponent,
  ],
  imports: [
    CommonModule,
    CareerRoutingModule,
    ComponentsModule,
    MaterialModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class CareerModule { }
