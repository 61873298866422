<section class="bg-color-secondary">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="col-12">
                <div class="inner-block d-flex align-items-center justify-content-center flex-column flex-lg-row justify-content-lg-between">
                    <div class="mb-4 mb-md-0 details-container" >
                        <p class="text-white mb-3">Who Are We?</p>
                        <h1 class="text-white mb-4">
                            We’re CareNX, <br class="last-br">A Digital
                            Women Healthcare Company For Healthcare Providers
                        </h1>
                        <div>
                            <a routerLink="/offering" color="white" button-filled>Our Innovations</a>            
                        </div>
                    </div>
                    <img alt="We’re CareNX, A Digital Maternity Solution Platform For Healthcare Providers" src="../../../../../assets/images/company/about/about-intro/about-intro.png">
                </div>
            </div>
        </div>
    </div>
</section>