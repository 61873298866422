<section id="sindicolpo-section">
    <div class="section-bg bg-color-secondary-lighter"></div>
    <div class="container inner-section">
        <div class="row">
            <div class="col-lg-6 inner-details mb-5 mb-lg-0" >
                <div class="details-container">
                    <p class="color-light">CareNX</p>
                    <h1 class="mb-3 color-secondary">SindiColpo</h1>
                    <p class="mb-3 color-primary my-4">
                        A wireless, interactive and smart-phone <br>
                        based cervical cancer screening solution <br>
                        to diagnose remotely from anywhere, at anytime
                    </p>
                    <div class="intro-buttons">
                        <a routerLink="/offering/sindicolpo" color="secondary" button-filled>
                            Know More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 inner-visual" >
                <div>
                    <div class="inner-img">
                        <!--<img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareNX" src="../../../../assets/images/home/products/fetosense.jpg">-->
                        <video src="../../../../assets/video/sindicolpo.mp4" [muted]="'muted'" playsinline preload="auto" loop autoplay></video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>