import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sindicolpo-components',
  templateUrl: './sindicolpo-components.component.html',
  styleUrls: ['./sindicolpo-components.component.scss']
})
export class SindiColpoComponentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
