import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-babybeat-components',
  templateUrl: './babybeat-components.component.html',
  styleUrls: ['./babybeat-components.component.scss']
})
export class BabybeatComponentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  scrollTo(sectionName){
    let element = document.getElementById(sectionName);
    element.scrollIntoView({behavior:'smooth'});
  }
}
