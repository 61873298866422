import { NgModule } from "@angular/core";
import { Routes, RouterModule } from '@angular/router';

import { ProductsComponent } from "./products.component";

const routes: Routes = [

  {
    path: "",
    component: ProductsComponent,
    data: {
      title: 'CareNX Fetosense NST/CTG Machine (Fetal Monitor) in India',
      keywords: 'NST / CTG Machine in India, Fetal Monitor, Fetal Doppler, Portable CTG, Smart CTG, Homecare CTG, Fetosense, CareNX',
      description: 'CareNX Fetosense is a Smartphone based digital, wireless, portable NST/CTG machine, Fetal Monitor, Fetal Doppler Manufacturer in Pune, Mumbai, India. 8766531223'
    }
  },
  {
    path: "babybeat",
    loadChildren: () => import('./babybeat/babybeat.module').then(m => m.BabybeatModule)
  },
  {
    path: "fetosense",
    loadChildren: () => import('./fetosense/fetosense.module').then(m => m.FetosenseModule)
  },
  {
    path: "anandimaa",
    loadChildren: () => import('./anandimaa/anandimaa.module').then(m => m.AnandimaaModule)
  },
  {
    path: "sindicolpo",
    loadChildren: () => import('./sindicolpo/sindicolpo.module').then(m => m.SindiColpoModule)
  },
  {
    path: "parent",
    loadChildren: () => import('./parent/parent.module').then(m => m.ParentModule)
  },
  {
    path: "healthcare",
    loadChildren: () => import('./healthcare/healthcare.module').then(m => m.HealthcareModule)
  },
  {
    path: "homecare",
    loadChildren: () => import('./homecare/homecare.module').then(m => m.HomecareModule)
  },
  {
    path: "covid",
    loadChildren: () => import('./covid/covid.module').then(m => m.CovidModule)
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
