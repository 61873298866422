import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products-sindicolpo',
  templateUrl: './products-sindicolpo.component.html',
  styleUrls: ['./products-sindicolpo.component.scss']
})
export class ProductsSindiColpoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
