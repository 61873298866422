<div>
    <!--<app-social-header></app-social-header>-->
    <app-main-navbar></app-main-navbar>
    <!--<app-fixed-socials></app-fixed-socials>-->
    <app-about-us-intro></app-about-us-intro>
    <app-page-nav></app-page-nav>
    <app-about-us-details></app-about-us-details>
    <app-about-journey></app-about-journey>
    <app-about-us-awards></app-about-us-awards>
    <app-partners></app-partners>
    <app-about-us-publications></app-about-us-publications>
    <!--<app-about-blogs></app-about-blogs>-->
    <app-stay-tuned></app-stay-tuned>
    <app-news></app-news>
    <app-footer></app-footer>
</div>